<template>
  <div class="flex flex-col items-center justify-center w-full bg-[#F2F6FA]">
    <section
      class="flex flex-col items-center w-full justify-center xl:w-[1285px]"
    >
      <div
        class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]"
      >
        <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex w-full">
          <img
            src="@/assets/img/sidebar/chl_n.svg"
            class="cursor-pointer w-6 md:w-[28px]"
            alt="post"
          />
          <div
            class="flex items-center max-sm:flex-col max-sm:gap-2 justify-between w-full"
          >
            <p class="text-base md:text-[22px] font-bold">Location Stats</p>
            <div class="" v-if="showLoadingMessage">
              <div class="flex gap-2">
                <span v-if="lastFetchedDate">
                  Stats last fetched {{ lastFetchedDate }}, fetching latest..
                </span>
                <span v-else> Fetching latest data.. </span>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="18"
                  :width="3"
                ></v-progress-circular>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full mx-auto mt-3">
        <div class="bg-white rounded-lg shadow-sm p-4 md:p-6">
          <div class="w-full items-center mx-auto px-4">
            <div
              class="relative flex justify-between flex-col md:flex-row items-start gap-6 md:items-center md:gap-2 grow"
            >
              <div class="gap-2 w-full md:w-1/3">
                <div class="relative location-dropdown">
                  <v-btn
                    @click="toggleStoreDropdown($event)"
                    outlined
                    color="primary"
                  >
                    {{ selectedWIDs?.length || 0 }} Selected Locations
                  </v-btn>
                  <LocationDropdown
                    ref="showStoreDropdown"
                    :stores="filteredStores"
                    :showStoreDropdown="showStoreDropdown"
                    v-model="selectedWIDs"
                    @change="fetchStorePosts"
                  />
                </div>
              </div>
              <v-select
                v-model="selectedDate"
                :items="dateOptions"
                label="Select Time Period"
                dense
                outlined
                :loading="isLoadingDates"
                class="min-w-[200px] flex-1 sm:flex-none date-select"
                hide-details
                @change="selectDateRange"
              ></v-select>
            </div>
          </div>

          <!-- Aggregated Data -->
          <div class="px-3 mx-2 mt-8 w-full">
            <div class="bg-white p-4 pt-0 rounded-lg shadow-md mt-4 w-[98%]">
              <div class="overflow-x-auto">
                <div class="min-w-[1000px]">
                  <div class="flex justify-between">
                    <aggregated-metric-ui
                      :metricName="'Maps'"
                      :tooltipMessage="getTooltipMessage('maps', 'combined')"
                      :aggregatedData="
                        aggregatedData.total_desktop_maps_impressions +
                        aggregatedData.total_mobile_maps_impressions
                      "
                      :metricKey="'maps'"
                      :isLoading="isLoading"
                      :previousMonthAggregatedDataLoading="
                        previousMonthAggregatedDataLoading
                      "
                      :previousMonthDataFetched="previousMonthDataFetched"
                      :selectedDate="selectedDate"
                      :metricsData="metricsData"
                      :chartData="chartData"
                      :metricData="
                        aggregatedData.total_desktop_maps_impressions +
                        aggregatedData.total_mobile_maps_impressions
                          ? aggregatedData.total_desktop_maps_impressions +
                            aggregatedData.total_mobile_maps_impressions
                          : getTotalMetric('maps')
                      "
                      :metricChange="getMetricChange('maps')"
                      :months="selectedDate.months"
                    />
                    <div class="border-l border-gray-300 mx-4"></div>
                    <aggregated-metric-ui
                      :metricName="'Search'"
                      :tooltipMessage="getTooltipMessage('search', 'combined')"
                      :aggregatedData="
                        aggregatedData.total_desktop_search_impressions +
                        aggregatedData.total_mobile_search_impressions
                      "
                      :metricKey="'search'"
                      :isLoading="isLoading"
                      :previousMonthAggregatedDataLoading="
                        previousMonthAggregatedDataLoading
                      "
                      :previousMonthDataFetched="previousMonthDataFetched"
                      :selectedDate="selectedDate"
                      :metricsData="metricsData"
                      :chartData="chartData"
                      :metricData="
                        aggregatedData.total_desktop_search_impressions +
                        aggregatedData.total_mobile_search_impressions
                          ? aggregatedData.total_desktop_search_impressions +
                            aggregatedData.total_mobile_search_impressions
                          : getTotalMetric('search')
                      "
                      :metricChange="getMetricChange('search')"
                      :months="selectedDate.months"
                    />
                    <div class="border-l border-gray-300 mx-4"></div>
                    <aggregated-metric-ui
                      :metricName="'Direction Requests'"
                      :tooltipMessage="
                        getTooltipMessage('directions', 'combined')
                      "
                      :aggregatedData="aggregatedData.total_direction_requests"
                      :metricKey="'directions'"
                      :isLoading="isLoading"
                      :previousMonthAggregatedDataLoading="
                        previousMonthAggregatedDataLoading
                      "
                      :previousMonthDataFetched="previousMonthDataFetched"
                      :selectedDate="selectedDate"
                      :metricsData="metricsData"
                      :chartData="chartData"
                      :metricData="
                        aggregatedData.total_direction_requests
                          ? aggregatedData.total_direction_requests
                          : getTotalMetric('directions')
                      "
                      :metricChange="getMetricChange('directions')"
                      :months="selectedDate.months"
                    />
                    <div class="border-l border-gray-300 mx-4"></div>
                    <aggregated-metric-ui
                      :metricName="'Call Clicks'"
                      :tooltipMessage="getTooltipMessage('calls', 'combined')"
                      :aggregatedData="
                        aggregatedData.total_call_clicks
                          ? aggregatedData.total_call_clicks
                          : getTotalMetric('calls')
                      "
                      :metricKey="'calls'"
                      :isLoading="isLoading"
                      :previousMonthAggregatedDataLoading="
                        previousMonthAggregatedDataLoading
                      "
                      :previousMonthDataFetched="previousMonthDataFetched"
                      :selectedDate="selectedDate"
                      :metricsData="metricsData"
                      :chartData="chartData"
                      :metricData="
                        aggregatedData.total_call_clicks
                          ? aggregatedData.total_call_clicks
                          : getTotalMetric('calls')
                      "
                      :metricChange="getMetricChange('calls')"
                      :months="selectedDate.months"
                    />
                    <div class="border-l border-gray-300 mx-4"></div>
                    <aggregated-metric-ui
                      :metricName="'Website Clicks'"
                      :tooltipMessage="getTooltipMessage('website', 'combined')"
                      :aggregatedData="aggregatedData.total_website_clicks"
                      :metricKey="'website'"
                      :isLoading="isLoading"
                      :previousMonthAggregatedDataLoading="
                        previousMonthAggregatedDataLoading
                      "
                      :previousMonthDataFetched="previousMonthDataFetched"
                      :selectedDate="selectedDate"
                      :metricsData="metricsData"
                      :chartData="chartData"
                      :metricData="
                        aggregatedData.total_website_clicks
                          ? aggregatedData.total_website_clicks
                          : getTotalMetric('website')
                      "
                      :metricChange="getMetricChange('website')"
                      :months="selectedDate.months"
                    />
                    <div class="border-l border-gray-300 mx-4"></div>
                    <aggregated-metric-ui
                      :metricName="'Bookings'"
                      :tooltipMessage="
                        getTooltipMessage('bookings', 'combined')
                      "
                      :aggregatedData="aggregatedData.total_bookings"
                      :metricKey="'bookings'"
                      :isLoading="isLoading"
                      :previousMonthAggregatedDataLoading="
                        previousMonthAggregatedDataLoading
                      "
                      :previousMonthDataFetched="previousMonthDataFetched"
                      :selectedDate="selectedDate"
                      :metricsData="metricsData"
                      :chartData="chartData"
                      :metricData="
                        aggregatedData.total_bookings
                          ? aggregatedData.total_bookings
                          : getTotalMetric('bookings')
                      "
                      :metricChange="getMetricChange('bookings')"
                      :months="selectedDate.months"
                    />
                    <div class="border-l border-gray-300 mx-4"></div>
                    <aggregated-metric-ui
                      :metricName="'Food Orders'"
                      :tooltipMessage="
                        getTooltipMessage('food_orders', 'combined')
                      "
                      :aggregatedData="aggregatedData.total_food_orders"
                      :metricKey="'food_orders'"
                      :isLoading="isLoading"
                      :previousMonthAggregatedDataLoading="
                        previousMonthAggregatedDataLoading
                      "
                      :previousMonthDataFetched="previousMonthDataFetched"
                      :selectedDate="selectedDate"
                      :metricsData="metricsData"
                      :chartData="chartData"
                      :metricData="
                        aggregatedData.total_food_orders
                          ? aggregatedData.total_food_orders
                          : getTotalMetric('food_orders')
                      "
                      :metricChange="getMetricChange('food_orders')"
                      :months="selectedDate.months"
                    />
                    <div class="border-l border-gray-300 mx-4"></div>
                    <aggregated-metric-ui
                      :metricName="'Food Menu Clicks'"
                      :tooltipMessage="
                        getTooltipMessage('food_menu', 'combined')
                      "
                      :aggregatedData="aggregatedData.total_food_menu_clicks"
                      :metricKey="'food_menu'"
                      :isLoading="isLoading"
                      :previousMonthAggregatedDataLoading="
                        previousMonthAggregatedDataLoading
                      "
                      :previousMonthDataFetched="previousMonthDataFetched"
                      :selectedDate="selectedDate"
                      :metricsData="metricsData"
                      :chartData="chartData"
                      :metricData="
                        aggregatedData.total_food_menu_clicks
                          ? aggregatedData.total_food_menu_clicks
                          : getTotalMetric('food_menu')
                      "
                      :metricChange="getMetricChange('food_menu')"
                      :months="selectedDate.months"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 mt-2 mb-8 pt-4">
            <v-tabs
              v-model="tabIndex"
              background-color="transparent"
              color="#8056DE"
              slider-color="#8056DE"
              align-with-title
              class="no-left-margin"
            >
              <v-tab
                value="metrics"
                @click="handleTabChange('metrics')"
                class="px-2 ml-0 text-none"
              >
                Store Metrics
              </v-tab>
              <v-tab
                value="performance"
                @click="handleTabChange('performance')"
                class="text-none"
              >
                Store Performance
              </v-tab>
            </v-tabs>
          </div>

          <div
            v-if="activeTab === 'performance'"
            class="flex flex-col items-center justify-center w-full pb-20"
          >
            <section
              class="flex flex-col items-center w-full justify-center xl:w-[100%]"
            >
              <div
                class="w-full xl:w-[100%] flex justify-center items-center gap-4"
              >
                <div class="w-full bg-white">
                  <div
                    class="flex flex-col justify-between w-full px-5 mx-auto"
                  >
                    <div
                      class="p-4 bg-white shadow-lg rounded-lg max-w-[1900px]"
                    >
                      <div class="overflow-x-auto w-full">
                        <div v-if="isLoading">
                          <v-skeleton-loader
                            type="table"
                            class="mb-4"
                            boilerplate
                          ></v-skeleton-loader>
                        </div>
                        <div
                          v-else-if="
                            (!showLoadingMessage && !metricsData) ||
                            metricsData?.length === 0
                          "
                          class="flex justify-center items-center py-8"
                        >
                          <p class="text-gray-500">No data available</p>
                        </div>
                        <div v-else class="overflow-auto max-h-[700px]">
                          <div
                            class="bg-gray-100 min-w-[1000px] rounded-t-lg text-xs w-full sticky top-0 z-40"
                          >
                            <div
                              class="flex items-center justify-between text-gray-600 p-2 flex-wrap"
                            >
                              <div
                                class="w-1/6 text-start whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                                @click="sortData('location')"
                              >
                                Location
                                <v-icon small>
                                  {{
                                    sortBy === "location"
                                      ? sortDesc
                                        ? "mdi-arrow-down"
                                        : "mdi-arrow-up"
                                      : "mdi-arrow-up"
                                  }}
                                </v-icon>
                              </div>
                              <div
                                class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                                @click="sortData('desktop_maps_impressions')"
                              >
                                Desktop<br />
                                Maps
                                <tool-tip
                                  :message="
                                    getTooltipMessage('maps', 'desktop')
                                  "
                                >
                                  <v-icon small>mdi-information</v-icon>
                                </tool-tip>
                                <v-icon small>
                                  {{
                                    sortBy === "desktop_maps_impressions"
                                      ? sortDesc
                                        ? "mdi-arrow-down"
                                        : "mdi-arrow-up"
                                      : "mdi-arrow-up"
                                  }}
                                </v-icon>
                              </div>
                              <div
                                class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                                @click="sortData('mobile_maps_impressions')"
                              >
                                Mobile<br />
                                Maps

                                <tool-tip
                                  :message="getTooltipMessage('maps', 'mobile')"
                                >
                                  <v-icon small>mdi-information</v-icon>
                                </tool-tip>
                                <v-icon small>
                                  {{
                                    sortBy === "mobile_maps_impressions"
                                      ? sortDesc
                                        ? "mdi-arrow-down"
                                        : "mdi-arrow-up"
                                      : "mdi-arrow-up"
                                  }}
                                </v-icon>
                              </div>
                              <div
                                class="w-1/12 text-center whitespace-nowrap px-2"
                                @click="sortData('desktop_search_impressions')"
                              >
                                Desktop<br />
                                Search
                                <tool-tip
                                  :message="
                                    getTooltipMessage('search', 'desktop')
                                  "
                                >
                                  <v-icon small>mdi-information</v-icon>
                                </tool-tip>
                                <v-icon small>
                                  {{
                                    sortBy === "desktop_search_impressions"
                                      ? sortDesc
                                        ? "mdi-arrow-down"
                                        : "mdi-arrow-up"
                                      : "mdi-arrow-up"
                                  }}
                                </v-icon>
                              </div>
                              <div
                                class="w-1/12 text-center whitespace-nowrap px-2"
                                @click="sortData('mobile_search_impressions')"
                              >
                                Mobile<br />
                                Search
                                <tool-tip
                                  :message="
                                    getTooltipMessage('search', 'mobile')
                                  "
                                >
                                  <v-icon small>mdi-information</v-icon>
                                </tool-tip>
                                <v-icon small>
                                  {{
                                    sortBy === "mobile_search_impressions"
                                      ? sortDesc
                                        ? "mdi-arrow-down"
                                        : "mdi-arrow-up"
                                      : "mdi-arrow-up"
                                  }}
                                </v-icon>
                              </div>
                              <div
                                class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                                @click="sortData('direction_requests')"
                              >
                                Direction<br />
                                Requests
                                <tool-tip
                                  :message="getTooltipMessage('directions')"
                                >
                                  <v-icon small>mdi-information</v-icon>
                                </tool-tip>
                                <v-icon small>
                                  {{
                                    sortBy === "direction_requests"
                                      ? sortDesc
                                        ? "mdi-arrow-down"
                                        : "mdi-arrow-up"
                                      : "mdi-arrow-up"
                                  }}
                                </v-icon>
                              </div>
                              <div
                                class="w-1/12 text-center whitespace-nowrap px-5 cursor-pointer hover:bg-gray-200"
                                @click="sortData('call_clicks')"
                              >
                                Call<br />
                                Clicks
                                <tool-tip :message="getTooltipMessage('calls')">
                                  <v-icon small>mdi-information</v-icon>
                                </tool-tip>
                                <v-icon small>
                                  {{
                                    sortBy === "call_clicks"
                                      ? sortDesc
                                        ? "mdi-arrow-down"
                                        : "mdi-arrow-up"
                                      : "mdi-arrow-up"
                                  }}
                                </v-icon>
                              </div>
                              <div
                                class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                                @click="sortData('website_clicks')"
                              >
                                Website <br />
                                Clicks
                                <tool-tip
                                  :message="getTooltipMessage('website')"
                                >
                                  <v-icon small>mdi-information</v-icon>
                                </tool-tip>
                                <v-icon small>
                                  {{
                                    sortBy === "website_clicks"
                                      ? sortDesc
                                        ? "mdi-arrow-down"
                                        : "mdi-arrow-up"
                                      : "mdi-arrow-up"
                                  }}
                                </v-icon>
                              </div>
                              <div
                                class="w-1/12 text-center whitespace-nowrap pr-6 cursor-pointer hover:bg-gray-200"
                                @click="sortData('bookings')"
                              >
                                Bookings
                                <tool-tip
                                  :message="getTooltipMessage('bookings')"
                                >
                                  <v-icon small>mdi-information</v-icon>
                                </tool-tip>
                                <v-icon small>
                                  {{
                                    sortBy === "bookings"
                                      ? sortDesc
                                        ? "mdi-arrow-down"
                                        : "mdi-arrow-up"
                                      : "mdi-arrow-up"
                                  }}
                                </v-icon>
                              </div>
                              <div
                                class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                                @click="sortData('food_orders')"
                              >
                                Food <br />Orders
                                <tool-tip
                                  :message="getTooltipMessage('food_orders')"
                                >
                                  <v-icon small>mdi-information</v-icon>
                                </tool-tip>
                                <v-icon small>
                                  {{
                                    sortBy === "food_orders"
                                      ? sortDesc
                                        ? "mdi-arrow-down"
                                        : "mdi-arrow-up"
                                      : "mdi-arrow-up"
                                  }}
                                </v-icon>
                              </div>
                              <div
                                class="w-1/12 text-center whitespace-nowrap px-4 cursor-pointer hover:bg-gray-200"
                                @click="sortData('food_menu_clicks')"
                              >
                                Food Menu <br />Clicks
                                <tool-tip
                                  :message="getTooltipMessage('food_menu')"
                                >
                                  <v-icon small>mdi-information</v-icon>
                                </tool-tip>
                                <v-icon small>
                                  {{
                                    sortBy === "food_menu_clicks"
                                      ? sortDesc
                                        ? "mdi-arrow-down"
                                        : "mdi-arrow-up"
                                      : "mdi-arrow-up"
                                  }}
                                </v-icon>
                              </div>
                            </div>
                          </div>

                          <div class="min-w-[1000px]">
                            <div
                              v-for="metric in metricsData"
                              :key="metric.location.wid"
                              class="border-b border-gray-200"
                            >
                              <div
                                class="flex justify-between items-center w-full text-xs bg-white hover:bg-gray-100 py-2"
                              >
                                <div class="w-1/6 px-2">
                                  <div class="flex flex-col items-start">
                                    <span
                                      v-if="metric.location?.sub_locality"
                                      class="font-semibold"
                                      >{{ metric.location.sub_locality }}</span
                                    >
                                    <span
                                      v-else-if="
                                        !metric.location?.sub_locality &&
                                        metric.location?.storeLocation
                                      "
                                      class="text-[12px]"
                                      >{{ metric.location.storeLocation }}</span
                                    >
                                    <div
                                      class="flex gap-1 flex-col items-start"
                                    >
                                      <div
                                        v-if="metric.location?.city"
                                        class="text-xs"
                                      >
                                        {{ metric.location.city }}
                                      </div>
                                      <span
                                        v-if="metric.location?.state"
                                        class="text-xs"
                                        >{{ metric.location?.state }}</span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="w-1/12 text-center font-semibold px-2"
                                >
                                  {{ metric.desktop_maps_impressions }}
                                  <div
                                    v-if="
                                      previousMetrics[metric.location.wid] &&
                                      selectedDate?.months !== 12
                                    "
                                    :class="
                                      getPercentageClass(
                                        metric.desktop_maps_impressions,
                                        previousMetrics[metric.location.wid]
                                          .desktop_maps_impressions
                                      )
                                    "
                                    class="text-[10px] flex items-center justify-center gap-1"
                                  >
                                    <v-icon small>{{
                                      getPercentageIcon(
                                        metric.desktop_maps_impressions,
                                        previousMetrics[metric.location.wid]
                                          .desktop_maps_impressions
                                      )
                                    }}</v-icon>
                                    {{
                                      calculatePercentageChange(
                                        metric.desktop_maps_impressions,
                                        previousMetrics[metric.location.wid]
                                          .desktop_maps_impressions
                                      )
                                    }}%
                                  </div>
                                </div>
                                <div
                                  class="w-1/12 text-center font-semibold px-2"
                                >
                                  {{ metric.mobile_maps_impressions }}
                                  <div
                                    v-if="
                                      previousMetrics[metric.location.wid] &&
                                      selectedDate?.months !== 12
                                    "
                                    :class="
                                      getPercentageClass(
                                        metric.mobile_maps_impressions,
                                        previousMetrics[metric.location.wid]
                                          .mobile_maps_impressions
                                      )
                                    "
                                    class="text-[10px] flex items-center justify-center gap-1"
                                  >
                                    <v-icon small>{{
                                      getPercentageIcon(
                                        metric.mobile_maps_impressions,
                                        previousMetrics[metric.location.wid]
                                          .mobile_maps_impressions
                                      )
                                    }}</v-icon>
                                    {{
                                      calculatePercentageChange(
                                        metric.mobile_maps_impressions,
                                        previousMetrics[metric.location.wid]
                                          .mobile_maps_impressions
                                      )
                                    }}%
                                  </div>
                                </div>
                                <div
                                  class="w-1/12 text-center font-semibold px-2"
                                >
                                  {{ metric.desktop_search_impressions }}
                                  <div
                                    v-if="
                                      previousMetrics[metric.location.wid] &&
                                      selectedDate?.months !== 12
                                    "
                                    :class="
                                      getPercentageClass(
                                        metric.desktop_search_impressions,
                                        previousMetrics[metric.location.wid]
                                          .desktop_search_impressions
                                      )
                                    "
                                    class="text-[10px] flex items-center justify-center gap-1"
                                  >
                                    <v-icon small>{{
                                      getPercentageIcon(
                                        metric.desktop_search_impressions,
                                        previousMetrics[metric.location.wid]
                                          .desktop_search_impressions
                                      )
                                    }}</v-icon>
                                    {{
                                      calculatePercentageChange(
                                        metric.desktop_search_impressions,
                                        previousMetrics[metric.location.wid]
                                          .desktop_search_impressions
                                      )
                                    }}%
                                  </div>
                                </div>
                                <div
                                  class="w-1/12 text-center font-semibold px-2"
                                >
                                  {{ metric.mobile_search_impressions }}
                                  <div
                                    v-if="
                                      previousMetrics[metric.location.wid] &&
                                      selectedDate?.months !== 12
                                    "
                                    :class="
                                      getPercentageClass(
                                        metric.mobile_search_impressions,
                                        previousMetrics[metric.location.wid]
                                          .mobile_search_impressions
                                      )
                                    "
                                    class="text-[10px] flex items-center justify-center gap-1"
                                  >
                                    <v-icon small>{{
                                      getPercentageIcon(
                                        metric.mobile_search_impressions,
                                        previousMetrics[metric.location.wid]
                                          .mobile_search_impressions
                                      )
                                    }}</v-icon>
                                    {{
                                      calculatePercentageChange(
                                        metric.mobile_search_impressions,
                                        previousMetrics[metric.location.wid]
                                          .mobile_search_impressions
                                      )
                                    }}%
                                  </div>
                                </div>
                                <div
                                  class="w-1/12 text-center font-semibold px-2"
                                >
                                  {{ metric.direction_requests }}
                                  <div
                                    v-if="
                                      previousMetrics[metric.location.wid] &&
                                      selectedDate?.months !== 12
                                    "
                                    :class="
                                      getPercentageClass(
                                        metric.direction_requests,
                                        previousMetrics[metric.location.wid]
                                          .direction_requests
                                      )
                                    "
                                    class="text-[10px] flex items-center justify-center gap-1"
                                  >
                                    <v-icon small>{{
                                      getPercentageIcon(
                                        metric.direction_requests,
                                        previousMetrics[metric.location.wid]
                                          .direction_requests
                                      )
                                    }}</v-icon>
                                    {{
                                      calculatePercentageChange(
                                        metric.direction_requests,
                                        previousMetrics[metric.location.wid]
                                          .direction_requests
                                      )
                                    }}%
                                  </div>
                                </div>
                                <div
                                  class="w-1/12 text-center font-semibold px-2"
                                >
                                  {{ metric.call_clicks }}
                                  <div
                                    v-if="
                                      previousMetrics[metric.location.wid] &&
                                      selectedDate?.months !== 12
                                    "
                                    :class="
                                      getPercentageClass(
                                        metric.call_clicks,
                                        previousMetrics[metric.location.wid]
                                          .call_clicks
                                      )
                                    "
                                    class="text-[10px] flex items-center justify-center gap-1"
                                  >
                                    <v-icon small>{{
                                      getPercentageIcon(
                                        metric.call_clicks,
                                        previousMetrics[metric.location.wid]
                                          .call_clicks
                                      )
                                    }}</v-icon>
                                    {{
                                      calculatePercentageChange(
                                        metric.call_clicks,
                                        previousMetrics[metric.location.wid]
                                          .call_clicks
                                      )
                                    }}%
                                  </div>
                                </div>
                                <div
                                  class="w-1/12 text-center font-semibold px-2"
                                >
                                  {{ metric.website_clicks }}
                                  <div
                                    v-if="
                                      previousMetrics[metric.location.wid] &&
                                      selectedDate?.months !== 12
                                    "
                                    :class="
                                      getPercentageClass(
                                        metric.website_clicks,
                                        previousMetrics[metric.location.wid]
                                          .website_clicks
                                      )
                                    "
                                    class="text-[10px] flex items-center justify-center gap-1"
                                  >
                                    <v-icon small>{{
                                      getPercentageIcon(
                                        metric.website_clicks,
                                        previousMetrics[metric.location.wid]
                                          .website_clicks
                                      )
                                    }}</v-icon>
                                    {{
                                      calculatePercentageChange(
                                        metric.website_clicks,
                                        previousMetrics[metric.location.wid]
                                          .website_clicks
                                      )
                                    }}%
                                  </div>
                                </div>
                                <div
                                  class="w-1/12 text-center font-semibold px-2"
                                >
                                  {{ metric.bookings }}
                                  <div
                                    v-if="
                                      previousMetrics[metric.location.wid] &&
                                      selectedDate?.months !== 12
                                    "
                                    :class="
                                      getPercentageClass(
                                        metric.bookings,
                                        previousMetrics[metric.location.wid]
                                          .bookings
                                      )
                                    "
                                    class="text-[10px] flex items-center justify-center gap-1"
                                  >
                                    <v-icon small>{{
                                      getPercentageIcon(
                                        metric.bookings,
                                        previousMetrics[metric.location.wid]
                                          .bookings
                                      )
                                    }}</v-icon>
                                    {{
                                      calculatePercentageChange(
                                        metric.bookings,
                                        previousMetrics[metric.location.wid]
                                          .bookings
                                      )
                                    }}%
                                  </div>
                                </div>
                                <div
                                  class="w-1/12 text-center font-semibold px-2"
                                >
                                  {{ metric.food_orders }}
                                  <div
                                    v-if="
                                      previousMetrics[metric.location.wid] &&
                                      selectedDate?.months !== 12
                                    "
                                    :class="
                                      getPercentageClass(
                                        metric.food_orders,
                                        previousMetrics[metric.location.wid]
                                          .food_orders
                                      )
                                    "
                                    class="text-[10px] flex items-center justify-center gap-1"
                                  >
                                    <v-icon small>{{
                                      getPercentageIcon(
                                        metric.food_orders,
                                        previousMetrics[metric.location.wid]
                                          .food_orders
                                      )
                                    }}</v-icon>
                                    {{
                                      calculatePercentageChange(
                                        metric.food_orders,
                                        previousMetrics[metric.location.wid]
                                          .food_orders
                                      )
                                    }}%
                                  </div>
                                </div>
                                <div
                                  class="w-1/12 text-center font-semibold px-2"
                                >
                                  {{ metric.food_menu_clicks }}
                                  <div
                                    v-if="
                                      previousMetrics[metric.location.wid] &&
                                      selectedDate?.months !== 12
                                    "
                                    :class="
                                      getPercentageClass(
                                        metric.food_menu_clicks,
                                        previousMetrics[metric.location.wid]
                                          .food_menu_clicks
                                      )
                                    "
                                    class="text-[10px] flex items-center justify-center gap-1"
                                  >
                                    <v-icon small>{{
                                      getPercentageIcon(
                                        metric.food_menu_clicks,
                                        previousMetrics[metric.location.wid]
                                          .food_menu_clicks
                                      )
                                    }}</v-icon>
                                    {{
                                      calculatePercentageChange(
                                        metric.food_menu_clicks,
                                        previousMetrics[metric.location.wid]
                                          .food_menu_clicks
                                      )
                                    }}%
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="
                                  selectedDate?.months &&
                                  selectedDate.months !== 12
                                "
                                class="flex items-center justify-start w-full px-2 py-1"
                              >
                                <button
                                  @click="
                                    togglePreviousData(metric.location.wid)
                                  "
                                  class="text-xs text-gray-600 hover:text-gray-800 flex items-center gap-1"
                                >
                                  <v-icon small>{{
                                    showPreviousData[metric.location.wid]
                                      ? "mdi-chevron-up"
                                      : "mdi-chevron-down"
                                  }}</v-icon>
                                </button>
                              </div>
                              <div
                                v-if="
                                  showPreviousData[metric.location.wid] &&
                                  selectedDate.months !== 12
                                "
                                class="bg-gray-50 px-2 py-3 border-t border-gray-200"
                              >
                                <div
                                  v-if="!previousMetrics[metric.location.wid]"
                                  class="flex justify-between items-center w-full text-xs"
                                >
                                  <div class="w-1/6 px-2">
                                    <div class="skeleton-loader w-24 h-4"></div>
                                  </div>
                                  <div class="w-1/12 text-center">
                                    <div
                                      class="skeleton-loader w-12 h-4 mx-auto"
                                    ></div>
                                  </div>
                                  <div class="w-1/12 text-center">
                                    <div
                                      class="skeleton-loader w-12 h-4 mx-auto"
                                    ></div>
                                  </div>
                                  <div class="w-1/12 text-center">
                                    <div
                                      class="skeleton-loader w-12 h-4 mx-auto"
                                    ></div>
                                  </div>
                                  <div class="w-1/12 text-center">
                                    <div
                                      class="skeleton-loader w-12 h-4 mx-auto"
                                    ></div>
                                  </div>
                                  <div class="w-1/12 text-center">
                                    <div
                                      class="skeleton-loader w-12 h-4 mx-auto"
                                    ></div>
                                  </div>
                                  <div class="w-1/12 text-center">
                                    <div
                                      class="skeleton-loader w-12 h-4 mx-auto"
                                    ></div>
                                  </div>
                                  <div class="w-1/12 text-center">
                                    <div
                                      class="skeleton-loader w-12 h-4 mx-auto"
                                    ></div>
                                  </div>
                                  <div class="w-1/12 text-center">
                                    <div
                                      class="skeleton-loader w-12 h-4 mx-auto"
                                    ></div>
                                  </div>
                                  <div class="w-1/12 text-center">
                                    <div
                                      class="skeleton-loader w-12 h-4 mx-auto"
                                    ></div>
                                  </div>
                                </div>
                                <div
                                  v-else
                                  class="flex justify-between items-center w-full text-xs"
                                >
                                  <!-- Your existing previous metrics display -->
                                  <div class="w-1/6 px-2">
                                    <span class="text-gray-600"
                                      >Previous
                                      {{ selectedDate.months }} Months</span
                                    >
                                  </div>
                                  <div class="w-1/12 text-center">
                                    {{
                                      previousMetrics[metric.location.wid]
                                        .desktop_maps_impressions
                                    }}
                                  </div>
                                  <div class="w-1/12 text-center">
                                    {{
                                      previousMetrics[metric.location.wid]
                                        .mobile_maps_impressions
                                    }}
                                  </div>
                                  <div class="w-1/12 text-center">
                                    {{
                                      previousMetrics[metric.location.wid]
                                        .desktop_search_impressions
                                    }}
                                  </div>
                                  <div class="w-1/12 text-center">
                                    {{
                                      previousMetrics[metric.location.wid]
                                        .mobile_search_impressions
                                    }}
                                  </div>
                                  <div class="w-1/12 text-center">
                                    {{
                                      previousMetrics[metric.location.wid]
                                        .direction_requests
                                    }}
                                  </div>
                                  <div class="w-1/12 text-center">
                                    {{
                                      previousMetrics[metric.location.wid]
                                        .call_clicks
                                    }}
                                  </div>
                                  <div class="w-1/12 text-center">
                                    {{
                                      previousMetrics[metric.location.wid]
                                        .website_clicks
                                    }}
                                  </div>
                                  <div class="w-1/12 text-center">
                                    {{
                                      previousMetrics[metric.location.wid]
                                        .bookings
                                    }}
                                  </div>
                                  <div class="w-1/12 text-center">
                                    {{
                                      previousMetrics[metric.location.wid]
                                        .food_orders
                                    }}
                                  </div>
                                  <div class="w-1/12 text-center">
                                    {{
                                      previousMetrics[metric.location.wid]
                                        .food_menu_clicks
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="flex justify-center mt-6">
              <div class="flex items-center gap-4">
                <button
                  @click="prevPage('performance')"
                  :disabled="currentPage === 1"
                  class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border disabled:cursor-not-allowed border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
                >
                  Previous
                </button>
                <div class="flex items-center gap-2">
                  <span class="text-sm text-gray-700">Page</span>
                  <span class="font-medium">{{
                    totalPages ? currentPage : 0
                  }}</span>
                  <span class="text-sm text-gray-700">of</span>
                  <span class="font-medium">{{ totalPages || 0 }}</span>
                </div>
                <button
                  @click="nextPage('performance')"
                  :disabled="currentPage >= totalPages"
                  class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 hover:border hover:border-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div
            v-else-if="activeTab === 'metrics'"
            class="bg-white p-4 pt-0 pl-0 mt-4 w-full mx-2"
          >
            <div
              v-if="
                (!showLoadingMessage && !chartData && !isChartLoading) ||
                chartData?.length === 0
              "
              class="flex justify-center items-center py-8"
            >
              <p class="text-gray-500">No data available</p>
            </div>
            <div v-show="!isChartLoading && chartData?.length">
              <!-- Graph for mobile metrics -->
              <div>
                <div class="flex items-start gap-4">
                  <div v-for="metric in mobileMetricsModel" :key="metric.value">
                    <input
                      type="checkbox"
                      :id="metric.value"
                      v-model="selectedMobileMetricsModel"
                      :value="metric.value"
                      @change="
                        saveMetricsPreference(
                          'mobile',
                          selectedMobileMetricsModel
                        )
                      "
                      :style="{
                        accentColor: selectedMobileMetricsModel.includes(
                          metric.value
                        )
                          ? metricColors[metric.value]
                          : '',
                      }"
                    />
                    <label
                      :for="metric.value"
                      class="text-sm pl-2 font-semibold"
                      :style="{ color: metricColors[metric.value] }"
                      >{{ metric.text }}</label
                    >
                  </div>
                </div>

                <div class="relative h-[400px] w-full gap-9">
                  <canvas ref="mobileMetricsChart"></canvas>
                </div>
              </div>
              <!-- Graph for desktp metrics -->
              <div>
                <div class="flex items-start gap-4">
                  <div
                    v-for="metric in desktopMetricsModel"
                    :key="metric.value"
                  >
                    <input
                      type="checkbox"
                      :id="metric.value"
                      v-model="selectedDesktopMetricsModel"
                      :value="metric.value"
                      @change="
                        saveMetricsPreference(
                          'desktop',
                          selectedDesktopMetricsModel
                        )
                      "
                      :style="{
                        accentColor: selectedDesktopMetricsModel.includes(
                          metric.value
                        )
                          ? metricColors[metric.value]
                          : '',
                      }"
                    />
                    <label
                      :for="metric.value"
                      class="text-sm pl-2 font-semibold"
                      :style="{ color: metricColors[metric.value] }"
                      >{{ metric.text }}</label
                    >
                  </div>
                </div>
                <div class="relative h-[400px] w-full gap-9">
                  <canvas ref="desktopMetricsChart"></canvas>
                </div>
              </div>
              <!-- Graph for rest of the metrics -->
              <div>
                <div class="flex items-start gap-4">
                  <div v-for="metric in availableMetrics" :key="metric.value">
                    <input
                      type="checkbox"
                      :id="metric.value"
                      v-model="selectedMetricsModel"
                      :value="metric.value"
                      @change="
                        saveMetricsPreference('default', selectedMetricsModel)
                      "
                      :style="{
                        accentColor: selectedMetricsModel.includes(metric.value)
                          ? metricColors[metric.value]
                          : '',
                      }"
                    />
                    <label
                      :for="metric.value"
                      class="text-sm pl-2 font-semibold"
                      :style="{ color: metricColors[metric.value] }"
                      >{{ metric.text }}</label
                    >
                  </div>
                </div>
                <div class="relative h-[400px] w-full gap-9">
                  <canvas ref="metricsChart"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  collection,
  onSnapshot,
  query,
  where,
  getDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import { auth, db } from "@/utils/firebase.utils";
import ToolTip from "@/components/ui/ToolTip.vue";
import AggregatedMetricUi from "@/components/AggregatedMetricUi.vue";
import Chart from "chart.js/auto";
import LocationDropdown from "@/components/LocationDropdown.vue";
import moment from "moment-timezone";

export default {
  components: { ToolTip, LocationDropdown, AggregatedMetricUi },
  props: {},
  data() {
    return {
      stores: [],
      lastFetchedDate: null,
      partialData: {},
      tabIndex: 0,
      pendingWorkspaces: [],
      showStoreDropdown: false,
      selectedWIDs: [],
      metricsData: {},
      isLoading: false,
      isChartLoading: false,
      selectedDateRange: "Last 7 days",
      sortBy: "location",
      sortDesc: false,
      locationTitles: {},
      gmb_logo: "",
      dateOptions: [],
      selectedDate: null,
      isLoadingDates: false,
      metricsChart: null,
      mobileMetricsChart: null,
      desktopMetricsChart: null,
      chartData: [],
      selectedMetricsModel: [],
      selectedMobileMetricsModel: [],
      selectedDesktopMetricsModel: [],
      mobileMetricsModel: [
        { text: "Mobile Maps", value: "mobile_maps_impressions" },
        { text: "Mobile Search", value: "mobile_search_impressions" },
      ],
      desktopMetricsModel: [
        { text: "Desktop Maps", value: "desktop_maps_impressions" },
        { text: "Desktop Search", value: "desktop_search_impressions" },
      ],
      availableMetrics: [
        { text: "Direction Requests", value: "direction_requests" },
        { text: "Call Clicks", value: "call_clicks" },
        { text: "Website Clicks", value: "website_clicks" },
        { text: "Bookings", value: "bookings" },
        { text: "Food Orders", value: "food_orders" },
        { text: "Food Menu Clicks", value: "food_menu_clicks" },
      ],
      activeTab: "metrics",
      mobileMetricColors: {
        mobile_maps_impressions: "#48BB78",
        mobile_search_impressions: "#ED8936",
      },
      desktopMetricColors: {
        desktop_maps_impressions: "#4C51BF",
        desktop_search_impressions: "#FF6B6B",
      },
      metricColors: {
        desktop_maps_impressions: "#4C51BF",
        mobile_maps_impressions: "#48BB78",
        desktop_search_impressions: "#FF6B6B",
        mobile_search_impressions: "#ED8936",
        direction_requests: "#A888B5",
        call_clicks: "#38B2AC",
        website_clicks: "#DA498D",
        bookings: "#4299E1",
        food_orders: "#FF8383",
        food_menu_clicks: "#FCC737",
      },
      locationSearch: "",
      currentPage: 1,
      itemsPerPage: 10,
      paginatedWIDs: [],
      paginatedGraphWIDs: [],
      showPreviousData: {},
      previousMetrics: {},
      showLoadingMessage: false,
      allFetchedData: {},
      locationTitlesMapping: {},
      aggregatedData: {},
      dataFetched: false,
      previousAggregatedData: {},
      previousMonthDataFetched: false,
      previousMonthAggregatedDataLoading: false,
      metricsFetchedData: {},
      isStoreDataAvailable: true,
    };
  },
  computed: {
    isCurrentMonth() {
      const currentDate = new Date();
      return (
        this.selectedDate?.year === currentDate.getFullYear() &&
        this.selectedDate?.month === currentDate.getMonth() + 1
      );
    },
    totalPages() {
      return Math.ceil(this.selectedWIDs.length / this.itemsPerPage) || 0;
    },
    filteredStores() {
      if (!this.locationSearch) {
        return this.stores;
      }

      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter(
        (store) =>
          store.name?.toLowerCase().includes(searchTerm) ||
          store.sub_locality?.toLowerCase().includes(searchTerm) ||
          store.city?.toLowerCase().includes(searchTerm) ||
          store.state?.toLowerCase().includes(searchTerm)
      );
    },
  },
  watch: {
    selectedWIDs(newVal) {
      if (!newVal.length) {
        this.currentPage = 1;
        this.updatePaginatedWIDs();
        this.chartData = [];
        this.destroyChart("metricsChart");
        this.destroyChart("mobileMetricsChart");
        this.destroyChart("desktopMetricsChart");
      }
    },
    activeTab(newVal) {
      if (newVal === "metrics") {
        // Ensure chart is rendered when switching to metrics tab
        this.$nextTick(() => {
          this.updateAllCharts();
          this.$forceUpdate();
        });
      }
    },
    "selectedDate.months": {
      async handler(newVal) {
        // Reset previous data state
        this.showPreviousData = {};
        this.previousMetrics = {};
        this.metricsData = [];
        this.chartData = [];
        // Ensure reactivity
        this.$set(this, "chartData", []);
        this.$set(this, "metricsData", []);
        // Reopen all dropdowns and fetch new data
        this.selectedWIDs.forEach((wid) => {
          this.$set(this.showPreviousData, wid, true);
        });
        if (!this.selectedDate) return;
        await this.refreshData();
      },
      immediate: true,
    },
  },
  async created() {
    this.tabIndex = this.activeTab === "metrics" ? 0 : 1;
    this.generateDateOptions();
    this.selectedMetricsModel = this.selectedMetrics;
    this.selectedMobileMetricsModel = this.selectedMobileMetrics;
    this.selectedDesktopMetricsModel = this.selectedDesktopMetrics;
    if(this.selectedStatsMonth) {
      const date = this.dateOptions.find(option => option.value.months === this.selectedStatsMonth);
      this.selectedDate = date.value;
    }
    if(this.selectedStatsLocations?.length) {
      this.selectedWIDs = this.selectedStatsLocations;
    }
    // console.log('created data :',this.selectedStatsMonth, this.selectedStatsLocations, this.allFetchedLocationStatsData);
  },
  methods: {
    saveMetricsPreference(metricType, selectedMetrics) {
      switch (metricType) {
        case "mobile":
          this.$store.commit("SET_SELECTED_MOBILE_METRICS", selectedMetrics);
          break;
        case "desktop":
          this.$store.commit("SET_SELECTED_DESKTOP_METRICS", selectedMetrics);
          break;
        default:
          this.$store.commit("SET_SELECTED_METRICS", selectedMetrics);
      }
      this.updateChartForMetric(metricType, selectedMetrics);
    },
    updateChartForMetric(metricType, selectedMetrics) {
      let chartRefName;
      let metricsModel;

      switch (metricType) {
        case "mobile":
          chartRefName = "mobileMetricsChart";
          metricsModel = this.mobileMetricsModel;
          break;
        case "desktop":
          chartRefName = "desktopMetricsChart";
          metricsModel = this.desktopMetricsModel;
          break;
        default:
          chartRefName = "metricsChart";
          metricsModel = this.availableMetrics;
      }
      this.updateChart(
        chartRefName,
        selectedMetrics,
        this.chartData,
        this.metricColors,
        metricsModel
      );
    },
    async checkPreviousMonthMetrics() {
      if (this.dataFetched || (this.previousMonthMetricsInStore && Object.keys(this.previousMonthMetricsInStore).length > 0)) {
        let count = this.selectedWIDs.length;
        for (const wid of this.selectedWIDs) {
          this.previousMonthAggregatedDataLoading = true;
          await this.fetchPreviousMetrics(wid);
          count--;
        }
        if (count == 0) {
          this.previousMonthDataFetched = true;
          this.computeAggregatedMetrics();
        }
      }
    },
    async refreshData() {
      this.metricsData = [];
      this.chartData = [];
      if (this.selectedWIDs.length > 0) {
        await this.checkFetchingStatus();
        await this.checkPreviousMonthMetrics();
      }
    },
    isFetchedToday(wid, monthSelection) {
      const today = new Date().toLocaleDateString('en-GB'); // DD-MM-YYYY format
      const lastFetchDate = this.locationStatsLastFetched[wid]?.[monthSelection];
      return lastFetchDate === today;
    },
    async getLocationStatsData(fetchType) {
      const monthSelection = this.selectedDate.months;
      if (this.allFetchedLocationStatsData && 
        Object.keys(this.allFetchedLocationStatsData).length > 0) {
      const hasValidData = this.selectedWIDs.every(wid => 
        this.allFetchedLocationStatsData[wid]?.[monthSelection] && 
        this.isFetchedToday(wid, monthSelection)
      );
      
      if (hasValidData) {
        // Keep existing data in allFetchedData and just add/update necessary data
        if (!this.allFetchedData) {
          this.allFetchedData = {};
        }
        // Update allFetchedData with data from store for all locations
        Object.keys(this.allFetchedLocationStatsData).forEach(wid => {
          if (!this.allFetchedData[wid]) {
            this.allFetchedData[wid] = {};
          }
          if (this.allFetchedLocationStatsData[wid][monthSelection]) {
            this.allFetchedData[wid] = {
              ...this.allFetchedData[wid],
              ...this.allFetchedLocationStatsData[wid][monthSelection]
            };
          }
        });
        await this.updateMetricsAndChartData();
        await this.checkPreviousMonthMetrics();
        return;
      }
    }
      // If we get here, either data doesn't exist or wasn't fetched today
      if(fetchType == "from_query") {
        this.clearCachedMetricsData();
        await this.fetchData("from_query");
      }
    },
    clearCachedMetricsData() {
      this.$store.commit('SET_ALL_FETCHED_LOCATION_STATS_DATA', {
        wid: null,
        monthSelection: null,
        date: null,
        data: {}
      });
    },
    async checkFetchingStatus() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const oneDayAgo = new Date(today);
      oneDayAgo.setDate(today.getDate() - 1);
      // console.log("this.selectedWIDs:", this.selectedWIDs);
      let wids = [];
      this.pendingWorkspaces = [...this.selectedWIDs];
      const selectedMonths = this.selectedDate?.months;
      // await this.fetchData("from_query");
      await this.getLocationStatsData("from_query");
      for (const wid of this.selectedWIDs) {
        const workspaceRef = doc(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          wid
        );

        let lastFetchedDate;

        const workspaceDoc = await getDoc(workspaceRef);
        const data = workspaceDoc.data();
        // console.log('selectedMonths:', selectedMonths, data?.last_fetched_quarterly_gmb_analytics)
        let fetch_status;
        let monthly_fetched_date = data?.last_fetched_monthly_gmb_analytics
          ? new Date(data.last_fetched_monthly_gmb_analytics.seconds * 1000)
          : null;
        let quarterly_fetched_date;
        let halfyearly_fetched_date;
        let yearly_fetched_date;
        let fetched_today = false;
        if (selectedMonths == 1) {
          lastFetchedDate = data?.last_fetched_monthly_gmb_analytics
            ? new Date(data.last_fetched_monthly_gmb_analytics.seconds * 1000)
            : null;
          this.lastFetchedDate = moment(lastFetchedDate)
            .startOf("day")
            .fromNow();
          fetch_status = data.monthly_fetched || false;
        } else if (selectedMonths == 3) {
          lastFetchedDate = data?.last_fetched_quarterly_gmb_analytics
            ? new Date(data.last_fetched_quarterly_gmb_analytics.seconds * 1000)
            : null;
          quarterly_fetched_date = lastFetchedDate;
          this.lastFetchedDate = moment(lastFetchedDate)
            .startOf("day")
            .fromNow();
          fetch_status = data.quarterly_fetched || false;
        } else if (selectedMonths == 6) {
          lastFetchedDate = data?.last_fetched_halfyearly_gmb_analytics
            ? new Date(
                data.last_fetched_halfyearly_gmb_analytics.seconds * 1000
              )
            : null;
          halfyearly_fetched_date = lastFetchedDate;
          fetch_status = data.halfyearly_fetched || false;
          this.lastFetchedDate = moment(lastFetchedDate)
            .startOf("day")
            .fromNow();
        } else if (selectedMonths == 12) {
          lastFetchedDate = data?.last_fetched_yearly_gmb_analytics
            ? new Date(data.last_fetched_yearly_gmb_analytics.seconds * 1000)
            : null;
          yearly_fetched_date = lastFetchedDate;
          fetch_status = data.yearly_fetched || false;
          this.lastFetchedDate = moment(lastFetchedDate)
            .startOf("day")
            .fromNow();
        }
        // console.log("lastFetchedDate:", lastFetchedDate);
        if (lastFetchedDate) {
          lastFetchedDate.setHours(0, 0, 0, 0); // Set time to midnight for comparison
        }
        if (monthly_fetched_date) {
          monthly_fetched_date.setHours(0, 0, 0, 0);
          fetched_today =
            monthly_fetched_date.toDateString() === today.toDateString()
              ? true
              : false;
        }
        if (quarterly_fetched_date) {
          quarterly_fetched_date.setHours(0, 0, 0, 0);
        }
        if (halfyearly_fetched_date) {
          halfyearly_fetched_date.setHours(0, 0, 0, 0);
        }
        if (yearly_fetched_date) {
          yearly_fetched_date.setHours(0, 0, 0, 0);
        }
        // console.log(wid, 'monthly_fetched_date:', monthly_fetched_date)
        // console.log(wid, 'data.quarterly_fetched:', data.quarterly_fetched, 'quarterly_fetched_date:', quarterly_fetched_date, quarterly_fetched_date)
        // console.log(wid, 'data.halfyearly_fetched:', data.halfyearly_fetched, 'halfyearly_fetched_date:', halfyearly_fetched_date, halfyearly_fetched_date)
        // console.log(wid, 'data.yearly_fetched:', data.yearly_fetched, 'yearly_fetched_date:', yearly_fetched_date, yearly_fetched_date);

        const isDataUpToDate =
          fetch_status &&
          lastFetchedDate &&
          lastFetchedDate.toDateString() === today.toDateString();
        // console.log('isDataUpToDate:', isDataUpToDate, 'fetched_today:', fetched_today)
        // If selected month is 1 then we have already checked for monthly data
        // if selected month is 3 then check if  (quarterly is fetched ) || ( monthly is fetched and halfyearly is fetched ) || ( monthly is fetched and yearly is fetched )

        if (selectedMonths == 1 && isDataUpToDate) {
          // console.log('Monthly data is up to date, query it directly');
          this.pendingWorkspaces = this.pendingWorkspaces.filter(
            (id) => id !== wid
          );
          continue;
        } else if (
          selectedMonths == 3 &&
          (data.quarterly_fetched ||
            data.halfyearly_fetched ||
            data.yearly_fetched) &&
          (fetched_today ||
            isDataUpToDate ||
            (halfyearly_fetched_date &&
              halfyearly_fetched_date.toDateString() === today.toDateString()))
        ) {
          // console.log('Quarterly data is up to date, query it directly');
          this.pendingWorkspaces = this.pendingWorkspaces.filter(
            (id) => id !== wid
          );
          continue;
        } else if (
          selectedMonths == 6 &&
          (data.halfyearly_fetched || data.yearly_fetched) &&
          (fetched_today ||
            isDataUpToDate ||
            (yearly_fetched_date &&
              yearly_fetched_date.toDateString() === today.toDateString()))
        ) {
          // console.log('Half-yearly data is up to date, query it directly');
          this.pendingWorkspaces = this.pendingWorkspaces.filter(
            (id) => id !== wid
          );
          continue;
        } else if (
          selectedMonths == 12 &&
          data.yearly_fetched &&
          (fetched_today || isDataUpToDate)
        ) {
          // console.log('Yearly data is up to date, query it directly');
          this.pendingWorkspaces = this.pendingWorkspaces.filter(
            (id) => id !== wid
          );
          continue;
        }
        // Case 4: Data exists but needs to be refreshed
        // 1. First fetch existing data to show something immediately
        // 2. Then trigger a refresh in the background
        // console.log(
        //   "Data needs to be refreshed - showing existing data first, then refreshing", wid
        // );

        // 2. Then set up listener and trigger refresh in background
        this.showLoadingMessage = true;

        this.setupRealtimeListener(wid, selectedMonths);
        wids.push(wid);
      }
      if (wids.length > 0) {
        await this.triggerBackendRefresh(wids, selectedMonths);
      }
      // After checking all workspaces
      if (this.pendingWorkspaces.length === 0) {
        // All workspaces are up-to-date, just fetch the data
        console.log("All workspaces are up-to-date");
        this.showLoadingMessage = false;
      }
    },
    async triggerBackendRefresh(wids, selectedMonths) {
      try {
        // Call the backend function to refresh the data
        const refreshFunction = httpsCallable(functions, "refreshGmbMetrics");
        let startDate = new Date();
        let endDate = new Date();
        startDate.setMonth(startDate.getMonth() - selectedMonths);
        // Set endDate to yesterday
        endDate.setDate(endDate.getDate());

        const previousEndDate = new Date(startDate);
        const previousStartDate = new Date(previousEndDate);
        previousStartDate.setMonth(
          previousStartDate.getMonth() - selectedMonths
        );
        const payload = {
          uid: this.currentUID,
          wids,
          months: selectedMonths,
          refresh: true, // Flag to indicate this is a refresh request
          start_date: startDate,
          end_date: endDate,
          type: "",
          previous_start_date: previousStartDate,
        };

        // console.log("Triggering backend refresh with payload:", payload);
        refreshFunction(payload);
       } catch (error) {
        console.error("Error triggering backend refresh:", error);
        this.showLoadingMessage = false;
      }
    },

    setupRealtimeListener(wid, selectedMonths) {
      console.log(`Setting up real-time listener for workspace ${wid}`);
      const today = new Date();
      const workspaceRef = doc(
        db,
        "users",
        this.currentUID,
        "fly_workspaces",
        wid
      );
      // Store the unsubscribe function in a variable that persists outside the callback
      const listenerRef = { unsubscribe: null };

      // Set up a real-time listener for this workspace
      listenerRef.unsubscribe = onSnapshot(workspaceRef, (doc) => {
        const data = doc.data();
        let fetchStatus;

        if (
          selectedMonths == 1 &&
          data.last_fetched_monthly_gmb_analytics &&
          new Date(
            data.last_fetched_monthly_gmb_analytics.seconds * 1000
          ).toDateString() === today.toDateString()
        ) {
          fetchStatus = data.monthly_fetched;
        } else if (
          selectedMonths == 3 &&
          data.last_fetched_quarterly_gmb_analytics &&
          new Date(
            data.last_fetched_quarterly_gmb_analytics.seconds * 1000
          ).toDateString() === today.toDateString()
        ) {
          fetchStatus = data.quarterly_fetched;
        } else if (
          selectedMonths == 6 &&
          (data.halfyearly_fetched || data.yearly_fetched) &&
          data.last_fetched_quarterly_gmb_analytics &&
          new Date(
            data.last_fetched_quarterly_gmb_analytics.seconds * 1000
          ).toDateString() === today.toDateString()
        ) {
          fetchStatus = data.halfyearly_fetched;
        } else if (
          selectedMonths == 12 &&
          data.yearly_fetched &&
          data.last_fetched_quarterly_gmb_analytics &&
          new Date(
            data.last_fetched_quarterly_gmb_analytics.seconds * 1000
          ).toDateString() === today.toDateString()
        ) {
          fetchStatus = data.yearly_fetched;
        }
        // console.log(`fetchStatus for workspace ${wid}:`, fetchStatus);
        // Check if partial data is available
        if (data.partial_data && Object.keys(data.partial_data).length > 0) {
          // console.log(`Received partial data for workspace ${wid}`);
          this.processPartialData(wid, data.partial_data);
        }

        // If fetching is complete for this workspace
        if (fetchStatus === "fetched") {
          // console.log(`Fetching complete for workspace ${wid}`);
          // Remove from pending list
          this.pendingWorkspaces = this.pendingWorkspaces.filter(
            (id) => id !== wid
          );
          // console.log(`pendingWorkspaces.length: ${this.pendingWorkspaces.length}`);
          // console.log(`Still waiting for ${this.pendingWorkspaces.length} workspaces`);

          // Only unsubscribe this listener when this workspace is done
          listenerRef.unsubscribe();
          // console.log(`Unsubscribed listener for workspace ${wid}`);
          // If all workspaces are done, update UI
          if (this.pendingWorkspaces.length === 0) {
            console.log("All workspaces complete, updating UI");
            this.showLoadingMessage = false;
            this.fetchData("from_query");
          }
        }
      });
    },
    processPartialData(wid, partialData) {
      // Store the partial data
      this.$set(this.partialData, wid, partialData);

      // Update allFetchedData with partial data
      if (!this.allFetchedData[wid]) {
        this.$set(this.allFetchedData, wid, {});
      }
      // Merge partial data into allFetchedData
      Object.keys(partialData).forEach((dateKey) => {
        this.$set(
          this.allFetchedData[wid],
          dateKey,
          this.extractMetrics(partialData[dateKey])
        );
      });

      // Update metrics and chart with the partial data
      this.updateMetricsAndChartData();
    },

    updatePaginatedWIDs() {
      // Filter selectedWIDs to include only those that are selectable
      const selectableWIDs = this.selectedWIDs.filter((wid) => {
        const store = this.stores.find((store) => store.wid === wid);
        return store && store.isSelectable; // Ensure the store exists and is selectable
      });

      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      // Update paginatedWIDs with only selectable WIDs
      this.paginatedWIDs = selectableWIDs.slice(start, end);
      this.updateMetricsAndChartData();
      // console.log("this.paginatedWIDs:", this.paginatedWIDs);
    },
    nextPage(type) {
      if (type == "performance") {
        // console.log('in perf')
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
          this.updatePaginatedWIDs();
          this.updateMetricsAndChartData();
        }
      }
    },
    prevPage(type) {
      // console.log('prevPage: type:', type)
      if (type == "performance") {
        // console.log('prevPage if')
        if (this.currentPage > 1) {
          this.currentPage--;
          this.updatePaginatedWIDs();
          this.updateMetricsAndChartData();
        }
      }
    },
    generateRandomPath() {
      // Generate random points for the path
      const points = [];
      const segments = 8;
      for (let i = 0; i <= segments; i++) {
        const x = (i * 100) / segments;
        const y = 150 + Math.random() * 100 - 50; // Random y value around 150
        points.push(`${x},${y}`);
      }
      return points.join(" L ");
    },
    async handleTabChange(tab) {
      this.activeTab = tab;
      this.tabIndex = tab === "metrics" ? 0 : 1;
      this.destroyChart("metricsChart");
      this.destroyChart("mobileMetricsChart");
      this.destroyChart("desktopMetricsChart");
      this.activeTab = tab;
      this.$set(this, "activeTab", tab);
      await this.updateMetricsAndChartData();
      this.$forceUpdate();
    },
    destroyChart(chartRefName) {
      if (this[chartRefName]) {
        this[chartRefName].destroy();
        this[chartRefName] = null;
      }
    },
    updateChart(
      chartRefName,
      selectedMetrics,
      chartData,
      chartColors,
      metricsModel
    ) {
      let chartRef = this[chartRefName];
      if (!chartData?.length || !this.selectedWIDs.length) {
        if (chartRef) {
          chartRef.destroy();
          this[chartRefName] = null;
        }
        return;
      }

      const ctx = this.$refs[chartRefName]?.getContext("2d");
      if (!ctx) {
        // console.log("Canvas context is not available.");
        return;
      }

      const sortedChartData = [...chartData].sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
      let data = sortedChartData;
      // Function to find peaks and troughs
      const findSignificantPoints = (data, metric) => {
        if (data.length < 3) return data;
        let interval = null;
        const significantPoints = [];

        if (this.selectedDate.months == 3)
          interval = Math.ceil(data.length / 20);
        if (this.selectedDate.months == 6)
          interval = Math.ceil(data.length / 20);
        if (this.selectedDate.months == 12)
          interval = Math.ceil(data.length / 40);
        if (this.selectedDate.months != 1) {
          for (let i = 0; i < data.length; i++) {
            if (i % interval === 0 || i === 0 || i === data.length - 1) {
              significantPoints.push(data[i]);
            }
          }
        }
        return significantPoints;
      };

      const createGradient = (color) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, `${color}20`);
        gradient.addColorStop(1, `${color}00`);
        return gradient;
      };

      let significantData = null;
      const datasets = selectedMetrics.map((metric) => {
        significantData = findSignificantPoints(sortedChartData, metric);
        significantData.sort((a, b) => new Date(a.date) - new Date(b.date));
        // console.log('significantData:', significantData)
        if (this.selectedDate.months != 1) {
          data = significantData;
        }
        return {
          label: metricsModel.find((m) => m.value === metric)?.text || metric,
          data: data.map((day) => day[metric]),
          borderColor: chartColors[metric],
          backgroundColor: createGradient(chartColors[metric]),
          borderWidth: 2,
          pointBackgroundColor: chartColors[metric],
          pointBorderColor: "#FFFFFF",
          pointBorderWidth: 2,
          pointRadius: 4,
          pointHoverRadius: 6,
          pointHoverBorderWidth: 3,
          pointHoverBackgroundColor: "#FFFFFF",
          pointHoverBorderColor: chartColors[metric],
          tension: 0.3,
          fill: true,
        };
      });
      let labels = sortedChartData.map((day) =>
        new Date(day.date).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        })
      );
      if (this.selectedDate.months != 1) {
        // labels for all dates
        // labels = significantData.map((day) =>
        //   new Date(day.date).toLocaleDateString("en-US", {
        //     month: "short",
        //     year: "numeric"
        //   })
        // );

        labels = significantData.map((day, index) => {
          const date = new Date(day.date);
          const nextDate =
            index < significantData.length - 1
              ? new Date(significantData[index + 1].date)
              : null;
          const prevDate =
            index > 0 ? new Date(significantData[index - 1].date) : null;

          const isStartOfMonth =
            date.getDate() === 1 ||
            (prevDate && date.getMonth() !== prevDate.getMonth());

          const isEndOfMonth =
            nextDate && date.getMonth() !== nextDate.getMonth();
          const isFirstDataPoint = index === 0;
          const isLastDataPoint = index === significantData.length - 1;

          if (isFirstDataPoint || isStartOfMonth) {
            return date.toLocaleDateString("en-US", {
              month: "short",
              year: "numeric",
            });
          }
          return "";
        });
      }
      // console.log('labels:', labels)
      const config = {
        type: "line",
        data: {
          labels,
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
            mode: "index",
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              backgroundColor: "rgba(255, 255, 255, 0.98)",
              titleColor: "#1a202c",
              bodyColor: "#4a5568",
              titleFont: {
                size: 14,
                weight: "bold",
                family: "'Inter', sans-serif",
              },
              bodyFont: {
                size: 13,
                family: "'Inter', sans-serif",
              },
              padding: 12,
              borderColor: "rgba(0,0,0,0.1)",
              borderWidth: 1,
              cornerRadius: 8,
              displayColors: true,
              boxWidth: 8,
              boxHeight: 8,
              boxPadding: 4,
              usePointStyle: true,
              callbacks: {
                title: function (tooltipItems) {
                  const date = new Date(data[tooltipItems[0].dataIndex].date);
                  return date.toLocaleDateString("en-US", {
                    // weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                },
                label: function (context) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y.toLocaleString();
                  }
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 12,
                  family: "'Inter', sans-serif",
                },
                color: "#718096",
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                color: "rgba(0,0,0,0.05)",
                drawBorder: false,
              },
              ticks: {
                font: {
                  size: 12,
                  family: "'Inter', sans-serif",
                },
                color: "#718096",
                padding: 8,
                callback: function (value) {
                  if (value >= 1000000) {
                    return (value / 1000000).toFixed(1) + "M";
                  } else if (value >= 1000) {
                    return (value / 1000).toFixed(1) + "K";
                  }
                  return value;
                },
              },
            },
          },
          layout: {
            padding: {
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            },
          },
        },
      };
      if (chartRef) {
        chartRef.destroy();
      }
      this[chartRefName] = new Chart(ctx, config);
    },
    updateAllCharts() {
      this.updateChart(
        "metricsChart",
        this.selectedMetricsModel,
        this.chartData,
        this.metricColors,
        this.availableMetrics
      );
      this.updateChart(
        "mobileMetricsChart",
        this.selectedMobileMetricsModel,
        this.chartData,
        this.metricColors,
        this.mobileMetricsModel
      );
      this.updateChart(
        "desktopMetricsChart",
        this.selectedDesktopMetricsModel,
        this.chartData,
        this.metricColors,
        this.desktopMetricsModel
      );
    },

    generateDateOptions() {
      this.dateOptions = [
        {
          text: "Last 1 Month",
          value: {
            months: 1,
          },
        },
        {
          text: "Last 3 Months",
          value: {
            months: 3,
          },
        },
        {
          text: "Last 6 Months",
          value: {
            months: 6,
          },
        },
        {
          text: "Last 1 Year",
          value: {
            months: 12,
          },
        },
      ];
      if(!this.selectedStatsMonth) {
        this.selectedDate = this.dateOptions[0].value;
        this.$store.commit("SET_SELECTED_STATS_MONTH", this.selectedDate.months);
      }
    },
    sortData(column) {
      // If clicking the same column, reverse the sort direction
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        // New column, set it as sort key and default to ascending
        this.sortBy = column;
        this.sortDesc = false;
      }
      console.log("metricsData:", this.metricsData);
      // Sort the metricsData array
      this.metricsData.sort((a, b) => {
        let aVal = column === "location" ? a.location.sub_locality : a[column];
        let bVal = column === "location" ? b.location.sub_locality : b[column];

        // Handle numeric values
        if (typeof aVal === "number" && typeof bVal === "number") {
          return this.sortDesc ? bVal - aVal : aVal - bVal;
        }

        // Handle string values
        aVal = String(aVal).toLowerCase();
        bVal = String(bVal).toLowerCase();
        if (aVal < bVal) return this.sortDesc ? 1 : -1;
        if (aVal > bVal) return this.sortDesc ? -1 : 1;
        return 0;
      });
    },
    async fetchGMBLogo() {
      if (this.gmb_logo) {
        return;
      }
      try {
        const platformDoc = await getDoc(
          doc(db, "fly_platforms", "google_business_profile")
        );
        if (platformDoc.exists()) {
          const logoUrl = platformDoc.data().logo_url;
          this.gmb_logo = logoUrl;
        }
      } catch (error) {
        console.error("Error fetching platform logo:", error);
      }
    },
    getMetricChange(metricType) {
      if ((!this.previousMonthDataFetched || (!this.previousAggregatedData || 
      Object.keys(this.previousAggregatedData).length === 0)) || this.previousMonthAggregatedDataLoading) {
        return null;
      }
      let currentMetric = 0;
      let previousMetric = 0;
      try
      {
        switch (metricType) {
          case "maps":
            previousMetric = 
              (this.previousAggregatedData.desktop_maps_impressions || 0) +
              (this.previousAggregatedData.mobile_maps_impressions || 0);
            currentMetric = 
              (this.aggregatedData.total_desktop_maps_impressions || 0) +
              (this.aggregatedData.total_mobile_maps_impressions || 0);
            break;
          case "search":
            previousMetric = 
              (this.previousAggregatedData.desktop_search_impressions || 0) +
              (this.previousAggregatedData.mobile_search_impressions || 0);
            currentMetric = 
              (this.aggregatedData.total_desktop_search_impressions || 0) +
              (this.aggregatedData.total_mobile_search_impressions || 0);
            break;
          case "directions":
            previousMetric = this.previousAggregatedData.direction_requests || 0;
            currentMetric = this.aggregatedData.total_direction_requests || 0;
            break;
          case "calls":
            previousMetric = this.previousAggregatedData.call_clicks;
            currentMetric = this.aggregatedData.total_call_clicks;
            break;
          case "website":
            previousMetric = this.previousAggregatedData.website_clicks;
            currentMetric = this.aggregatedData.total_website_clicks;
            break;
          case "bookings":
            previousMetric = this.previousAggregatedData.bookings;
            currentMetric = this.aggregatedData.total_bookings;
            break;
          case "food_orders":
            previousMetric = this.previousAggregatedData.food_orders;
            currentMetric = this.aggregatedData.total_food_orders;
            break;
          case "food_menu":
            previousMetric = this.previousAggregatedData.food_menu_clicks;
            currentMetric = this.aggregatedData.total_food_menu_clicks;
            break;
          default:
            return null;
        }
        // Validate metrics before calculation
        if (previousMetric === undefined || currentMetric === undefined) {
          return null;
        }
        return this.calculatePercentageChange(currentMetric, previousMetric);
      } catch (error) {
        console.error(`Error calculating metric change for ${metricType}:`, error);
          return null;
      }
    },
    computeAggregatedMetrics() {
      // Initialize aggregated data for the previous month
      this.previousAggregatedData = {
        desktop_maps_impressions: 0,
        mobile_maps_impressions: 0,
        desktop_search_impressions: 0,
        mobile_search_impressions: 0,
        direction_requests: 0,
        call_clicks: 0,
        website_clicks: 0,
        bookings: 0,
        food_orders: 0,
        food_menu_clicks: 0,
      };

      for (const wid of this.selectedWIDs) {
        const metrics = this.previousMetrics[wid];
        if (!metrics) continue; // Skip if no metrics exist for this WID

        Object.keys(this.previousAggregatedData).forEach(key => {
          this.previousAggregatedData[key] += Number(metrics[key]) || 0;
        });
      }
      this.previousMonthAggregatedDataLoading = false;
    },
    getTotalMetric(metricType) {
      // If no data is available, return null
      // console.log('this.chartData:',Array.isArray(this.chartData),  this.chartData)
      if (
        (!this.metricsData || !Array.isArray(this.metricsData)) &&
        (!this.chartData || !Array.isArray(this.chartData))
      )
        return null;

      // For metrics tab (time series data)
      if (this.activeTab === "metrics" && this.chartData?.length) {
        // Sum up values across all dates for the selected metric type
        return this.chartData.reduce((total, dayData) => {
          switch (metricType) {
            case "maps":
              return (
                total +
                (parseInt(dayData.desktop_maps_impressions) || 0) +
                (parseInt(dayData.mobile_maps_impressions) || 0)
              );
            case "search":
              return (
                total +
                (parseInt(dayData.desktop_search_impressions) || 0) +
                (parseInt(dayData.mobile_search_impressions) || 0)
              );
            case "directions":
              return total + (parseInt(dayData.direction_requests) || 0);
            case "calls":
              return total + (parseInt(dayData.call_clicks) || 0);
            case "website":
              return total + (parseInt(dayData.website_clicks) || 0);
            case "bookings":
              return total + (parseInt(dayData.bookings) || 0);
            case "food_orders":
              return total + (parseInt(dayData.food_orders) || 0);
            case "food_menu":
              return total + (parseInt(dayData.food_menu_clicks) || 0);
            default:
              return total;
          }
        }, 0);
      }

      // For performance tab (location-based data)
      if (this.activeTab === "performance" && Array.isArray(this.metricsData)) {
        return this.metricsData.reduce((total, metric) => {
          if (!metric) return total;

          switch (metricType) {
            case "maps":
              return (
                total +
                (parseInt(metric.desktop_maps_impressions) || 0) +
                (parseInt(metric.mobile_maps_impressions) || 0)
              );
            case "search":
              return (
                total +
                (parseInt(metric.desktop_search_impressions) || 0) +
                (parseInt(metric.mobile_search_impressions) || 0)
              );
            case "directions":
              return total + (parseInt(metric.direction_requests) || 0);
            case "calls":
              return total + (parseInt(metric.call_clicks) || 0);
            case "website":
              return total + (parseInt(metric.website_clicks) || 0);
            case "bookings":
              return total + (parseInt(metric.bookings) || 0);
            case "food_orders":
              return total + (parseInt(metric.food_orders) || 0);
            case "food_menu":
              return total + (parseInt(metric.food_menu_clicks) || 0);
            default:
              return total;
          }
        }, 0);
      }

      return 0;
    },
    getTooltipMessage(metric, type = "default") {
      const messages = {
        maps: {
          desktop: "Business impressions on Google Maps on Desktop devices",
          mobile: "Business impressions on Google Maps on Mobile devices",
          combined: "Total Maps impressions across all locations",
        },
        search: {
          desktop: "Business impressions on Google Search on Desktop devices",
          mobile: "Business impressions on Google Search on Mobile devices",
          combined: "Total Search impressions across all locations",
        },
        directions: {
          default: "Total direction requests across all locations",
          combined: "Total direction requests across all locations",
        },
        calls: {
          default:
            "The number of times the business profile call button was clicked",
          combined: "Total call clicks across all locations",
        },
        website: {
          default:
            "The number of times the business profile website was clicked",
          combined: "Total website clicks across all locations",
        },
        bookings: {
          default:
            "The number of bookings made from the business profile via Reserve with Google",
          combined: "Total bookings across all locations",
        },
        food_orders: {
          default:
            "The number of food orders received from the business profile",
          combined: "Total food orders across all locations",
        },
        food_menu: {
          default:
            "The number of clicks to view or interact with the menu content on the business profile",
          combined:
            "Total number of clicks to interact with the menu content across all locations",
        },
      };

      // Return appropriate message based on metric and type
      if (type === "desktop") {
        return messages[metric]?.desktop || messages[metric]?.default;
      } else if (type === "mobile") {
        return messages[metric]?.mobile || messages[metric]?.default;
      } else if (type === "combined") {
        return messages[metric]?.combined || messages[metric]?.default;
      } else {
        return messages[metric]?.default;
      }
    },
    handleClickOutside(event) {
      const profileDropdown =
        this.$refs.showStoreDropdown?.$refs.showStoreDropdown;
      if (profileDropdown && !profileDropdown.contains(event.target)) {
        this.closeProfileDropdown();
      }
    },
    closeProfileDropdown() {
      if (this.showStoreDropdown) {
        this.showStoreDropdown = false;
      }
    },
    toggleStoreDropdown(event) {
      this.showStoreDropdown = !this.showStoreDropdown;
      event.stopPropagation();
    },
    async fetchStorePosts(profile) {
      this.selectedWIDs = profile.filter((item) =>
        this.stores.some((l) => l.wid === item && l.isSelectable)
      );
      // Update checked state for all stores
      this.stores.forEach(store => {
        if (store.isSelectable) {
          store.checked = this.selectedWIDs.includes(store.wid);
          this.fetchPreviousMetrics(store.wid);
        }
      });
      console.log('this.selectedWIDs orig:', this.selectedWIDs)
      this.$store.commit("SET_SELECTED_STATS_LOCATIONS", this.selectedWIDs);
      this.showStoreDropdown = false;
      // Clear chart data if no locations selected
      if (this.selectedWIDs.length === 0) {
        this.chartData = [];
        this.aggregatedData = {
          total_desktop_maps_impressions: null,
          total_mobile_maps_impressions: null,
          total_desktop_search_impressions: null,
          total_mobile_search_impressions: null,
          total_direction_requests: null,
          total_call_clicks: null,
          total_website_clicks: null,
          total_bookings: null,
          total_food_orders: null,
          total_food_menu_clicks: null,
        };
        this.destroyChart("metricsChart");
        this.destroyChart("mobileMetricsChart");
        this.destroyChart("desktopMetricsChart");
        return; // Don't fetch data if no locations selected
      }
      this.computeAggregatedMetrics();
      this.updatePaginatedWIDs();
    },

    async fetchData(fetch_type) {
      try {
        if (fetch_type) {
          this.showLoadingMessage = false;
          this.isChartLoading = true;
          this.$loader.show("Fetching stats, please wait...");
        }
        if (this.chartData?.length && this.activeTab == "metrics") {
          return this.chartData;
        } else if (
          this.metricsData?.length &&
          this.activeTab == "performance"
        ) {
          return this.metricsData;
        }
        this.metricsData = [];
        this.chartData = [];
        this.allFetchedData = {};
        this.aggregatedData = {};
        this.dataFetched = false;
        this.isLoading = true;

        const selectedMonths = this.selectedDate?.months;
        let wids = this.selectedWIDs;

        let startDate = new Date();
        let endDate = new Date();
        startDate.setMonth(startDate.getMonth() - selectedMonths);
        // Set endDate to yesterday
        endDate.setDate(endDate.getDate());

        const previousEndDate = new Date(startDate);
        const previousStartDate = new Date(previousEndDate);
        previousStartDate.setMonth(
          previousStartDate.getMonth() - selectedMonths
        );

        const connectedAppsPromises = wids?.map(async (wid) => {
          const connectedAppsRef = collection(
            db,
            "users",
            this.currentUID,
            "fly_workspaces",
            wid,
            "connected-apps"
          );
          const gbpDoc = await getDocs(
            query(
              connectedAppsRef,
              where("__name__", "==", "google_business_profile")
            )
          );
          return { wid, gbpDoc };
        });

        const payload = {
          uid: this.currentUID,
          wids: wids,
          months: selectedMonths,
          start_date: startDate,
          end_date: endDate,
          type: fetch_type || "",
          previous_start_date: previousStartDate,
        };

        const connectedAppsResults = await Promise.all(connectedAppsPromises);
        // Process results and build payloads

        connectedAppsResults.forEach(({ wid, gbpDoc }) => {
          if (gbpDoc.empty) return;

          const gbpData = gbpDoc.docs[0].data();
          const accounts = gbpData.accounts;
          if (!accounts?.length) return;

          const locations = accounts[0].locations;
          if (!locations?.length) return;
          const location = locations[0];

          this.locationTitlesMapping[wid] = {
            title: location.title,
            locationId: location.name,
            wid,
          };
        });

        const fetchPlatformMetrics = httpsCallable(
          functions,
          "refreshGmbMetrics"
        );
        const result = await fetchPlatformMetrics(payload);
        console.log("fetchPlatformMetrics result:", result);
        this.dataFetched = result.data[0].status || false;

        result.data?.forEach((item) => {
          const detailedData = item?.data?.detailedData || [];

          detailedData.forEach((metric) => {
            // const locationId = metric.location_id.split("/").pop();
            if (!this.allFetchedData[metric.wid]) {
              this.allFetchedData[metric.wid] = {};
            }
            const dateKey = metric?.date?.value;
            // Store data nested by date
            if (!this.allFetchedData[metric.wid][dateKey]) {
              this.$set(this.allFetchedData[metric.wid], dateKey, {});
            }
            this.allFetchedData[metric.wid][dateKey] =
              this.extractMetrics(metric);
              this.$store.commit('SET_ALL_FETCHED_LOCATION_STATS_DATA', {
                wid: metric.wid,
                monthSelection: selectedMonths,
                date: dateKey,
                data: this.extractMetrics(metric),
              });
          });
        });

        console.log("this.allFetchedData::", this.allFetchedData);
        if (result.data[0].status) {
          await this.updateMetricsAndChartData();
        }

        // this.sortData("location");
        // if (this.activeTab == "performance") {
        // }
      } catch (error) {
        console.error("Error fetching metrics:", error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
        if (fetch_type) this.$loader.hide();
        this.isChartLoading = false;
      }
    },
    async updateMetricsAndChartData() {
      let selectedWIDs = this.selectedStatsLocations?.length ? this.selectedStatsLocations :this.selectedWIDs;
      this.metricsData = [];
      this.chartData = [];
      this.$set(this, "chartData", []);
      // Calculate date range once
      const endDate = new Date();
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - this.selectedDate.months);
      endDate.setDate(endDate.getDate() - 1);
      // Reset aggregated data with same structure
      this.aggregatedData = {
        total_desktop_maps_impressions: 0,
        total_mobile_maps_impressions: 0,
        total_desktop_search_impressions: 0,
        total_mobile_search_impressions: 0,
        total_direction_requests: 0,
        total_call_clicks: 0,
        total_website_clicks: 0,
        total_bookings: 0,
        total_food_orders: 0,
        total_food_menu_clicks: 0,
      };

      // Create Map for faster lookups
      const storesMap = new Map(this.stores.map(store => [store.wid, store]));
      const dateWiseMetrics = {};
      // Process aggregated data for all selected locations
      for (const wid of selectedWIDs) {
        if (!this.allFetchedData[wid]) continue;
        
        const locationData = this.allFetchedData[wid];
        const validDates = Object.keys(locationData).filter(date => {
          const dateObj = new Date(date);
          return dateObj >= startDate && dateObj <= endDate;
        });

        validDates.forEach(date => {
          const dailyMetrics = locationData[date];
          // Maintain same aggregation logic
          this.aggregatedData.total_desktop_maps_impressions += 
            dailyMetrics.desktop_maps_impressions || 0;
          this.aggregatedData.total_mobile_maps_impressions += 
            dailyMetrics.mobile_maps_impressions || 0;
          this.aggregatedData.total_desktop_search_impressions += 
            dailyMetrics.desktop_search_impressions || 0;
          this.aggregatedData.total_mobile_search_impressions += 
            dailyMetrics.mobile_search_impressions || 0;
          this.aggregatedData.total_direction_requests += 
            dailyMetrics.direction_requests || 0;
          this.aggregatedData.total_call_clicks += 
            dailyMetrics.call_clicks || 0;
          this.aggregatedData.total_website_clicks += 
            dailyMetrics.website_clicks || 0;
          this.aggregatedData.total_bookings += 
            dailyMetrics.bookings || 0;
          this.aggregatedData.total_food_orders += 
            dailyMetrics.food_orders || 0;
          this.aggregatedData.total_food_menu_clicks += 
            dailyMetrics.food_menu_clicks || 0;
        });
      }

      // Process metrics for paginated locations
      await Promise.all(this.paginatedWIDs.map(async wid => {
        if (!this.allFetchedData[wid]) return;

        const locationData = this.allFetchedData[wid];
        const store = storesMap.get(wid) || {};

        // Calculate filtered metrics maintaining same structure
        const filteredMetrics = Object.keys(locationData)
          .filter(date => {
            const dateObj = new Date(date);
            return dateObj >= startDate && dateObj <= endDate;
          })
          .reduce((acc, date) => {
            const dailyMetrics = locationData[date];
            Object.keys(dailyMetrics).forEach(key => {
              acc[key] = (acc[key] || 0) + (dailyMetrics[key] || 0);
            });
            return acc;
          }, {});

        // Create metrics object with same structure
        const metrics = {
          location: {
            wid: wid,
            sub_locality: store.sub_locality || "",
            city: store.city || "",
            state: store.state || "",
            storeLocation: store.storeLocation || "",
            name: store.name || 
                  this.stores.find(store => store.wid === wid)?.title || "",
            title: this.locationTitlesMapping[wid]?.title || "",
          },
          ...filteredMetrics,
        };

        this.metricsData.push(metrics);

        // Process date-wise metrics maintaining same structure
        Object.keys(locationData).forEach(date => {
          const dateObj = new Date(date);
          if (dateObj >= startDate && dateObj <= endDate) {
            if (!dateWiseMetrics[date]) {
              dateWiseMetrics[date] = {
                date,
                desktop_maps_impressions: 0,
                mobile_maps_impressions: 0,
                desktop_search_impressions: 0,
                mobile_search_impressions: 0,
                direction_requests: 0,
                call_clicks: 0,
                website_clicks: 0,
                bookings: 0,
                food_orders: 0,
                food_menu_clicks: 0,
              };
            }

            const dailyMetrics = locationData[date];
            dateWiseMetrics[date].desktop_maps_impressions += 
              dailyMetrics.desktop_maps_impressions || 0;
            dateWiseMetrics[date].mobile_maps_impressions += 
              dailyMetrics.mobile_maps_impressions || 0;
            dateWiseMetrics[date].desktop_search_impressions += 
              dailyMetrics.desktop_search_impressions || 0;
            dateWiseMetrics[date].mobile_search_impressions += 
              dailyMetrics.mobile_search_impressions || 0;
            dateWiseMetrics[date].direction_requests += 
              dailyMetrics.direction_requests || 0;
            dateWiseMetrics[date].call_clicks += 
              dailyMetrics.call_clicks || 0;
            dateWiseMetrics[date].website_clicks += 
              dailyMetrics.website_clicks || 0;
            dateWiseMetrics[date].bookings += 
              dailyMetrics.bookings || 0;
            dateWiseMetrics[date].food_orders += 
              dailyMetrics.food_orders || 0;
            dateWiseMetrics[date].food_menu_clicks += 
              dailyMetrics.food_menu_clicks || 0;
          }
        });
      }));

      // Convert dateWiseMetrics to chartData maintaining same structure
      this.chartData = Object.keys(dateWiseMetrics).map(date => ({
        date,
        ...dateWiseMetrics[date],
      }));

      // Ensure reactivity while maintaining data structure
      await this.$nextTick();
      this.$set(this, "metricsData", [...this.metricsData]);
      this.$set(this, "chartData", [...this.chartData]);
      this.$set(this, "aggregatedData", { ...this.aggregatedData });

      await this.updateAllCharts();
    },

    async fetchStores() {
      const uid = this.currentUID;
      const storeRef = collection(db, "users", uid, "fly_workspaces");

      // Fetch store documents once
      const querySnapshot = await getDocs(storeRef);
      this.stores = [];
      this.selectedWIDs = [];

      // Process each store document
      const storePromises = querySnapshot.docs.map(async (doc) => {
        let storeData = doc.data();
        if (
          storeData.storeWorkspace &&
          storeData.admin === this.WID &&
          (storeData.connected_apps?.includes("google_business_profile") ||
            storeData.shallowConnect)
        ) {
          storeData.wid = doc.id;
          storeData.type = "store";
          storeData.display_name = `${storeData.sub_locality} - ${storeData.name}`;
          let verificationState = null;
          if (
            !storeData.shallowConnect &&
            storeData.connected_apps?.includes("google_business_profile")
          ) {
            const connectedAppsRef = collection(
              db,
              "users",
              uid,
              "fly_workspaces",
              doc.id,
              "connected-apps"
            );
            const gbpDoc = await getDocs(
              query(
                connectedAppsRef,
                where("__name__", "==", "google_business_profile")
              )
            );
            // Check verification state in workspace doc first
            if (storeData.verificationDetails) {
              verificationState =
                storeData.verificationDetails?.verification_state;
            } else if (!gbpDoc.empty) {
              const gbpData = gbpDoc.docs[0].data();
              const accounts = gbpData.accounts;
              // this.selectedWIDs.push(doc.id);
              // Check verification state of the first account and first location
              if (accounts.length > 0 && accounts[0].locations.length > 0) {
                verificationState = accounts[0].locations[0].verification_state;
              }
            }
            if (verificationState !== "COMPLETED") {
              storeData.isSelectable = false; // Mark as non-selectable
              storeData.checked = false;
              storeData.tooltipMessage = "This location is not verified";
            } else {
              storeData.isSelectable = true;
              // Only set checked if it's in selectedStatsLocations
              storeData.checked = this.selectedStatsLocations?.includes(doc.id) || false;
              
              // Add to selectedWIDs only if no previous selection exists
              if (!this.selectedStatsLocations?.length) {
                this.selectedWIDs.push(doc.id);
                storeData.checked = true;
              }
            }
          }
          if (storeData.shallowConnect) {
            storeData.checked = false;
            storeData.tooltipMessage =
              "Connect Google Business Profile to view analytics";
            storeData.isSelectable = false; // Mark as non-selectable
          }
          this.stores.push(storeData);

          // Initialize showPreviousData to true for this store
          this.$set(this.showPreviousData, doc.id, true);
        }
      });

      // Wait for all store promises to resolve
      await Promise.all(storePromises);
      if(this.selectedStatsLocations?.length) {
        this.selectedWIDs = [...this.selectedStatsLocations];
          // Update checked state for all stores
          this.stores.forEach(store => {
            if (store.isSelectable) {
              store.checked = this.selectedStatsLocations.includes(store.wid);
            }
          });      
        }
      this.$store.commit("SET_SELECTED_STATS_LOCATIONS", this.selectedWIDs);
      this.updatePaginatedWIDs();

      // Fetch metrics for all initially selected stores
      if (this.selectedWIDs.length > 0) {
        await this.refreshData();
      }
    },
    async selectDateRange() {
      this.$store.commit("SET_SELECTED_STATS_MONTH", this.selectedDate.months);
      this.previousAggregatedData = {};
      this.previousMonthDataFetched = false;
      this.metricsData = [];
      this.chartData = [];
      if (!this.selectedDate) return;
      this.refreshData();

      // Reset and reopen all dropdowns
      this.selectedWIDs.forEach((wid) => {
        this.$set(this.showPreviousData, wid, true);
      });
    },
    hasMetrics(metrics) {
      return Object.keys(metrics || {}).some(
        (key) =>
          metrics[key] !== undefined &&
          [
            "desktop_maps_impressions",
            "mobile_maps_impressions",
            "desktop_search_impressions",
            "mobile_search_impressions",
            "direction_requests",
            "call_clicks",
            "website_clicks",
            "bookings",
            "food_orders",
            "food_menu_clicks",
          ].includes(key)
      );
    },
    hasMetricsNew(metrics) {
      // Check if metrics is an array and has elements
      if (!Array.isArray(metrics) || metrics.length === 0) {
        return false;
      }

      // Check each metric object for the required keys
      return metrics.some((metric) =>
        [
          "desktop_maps_impressions",
          "mobile_maps_impressions",
          "desktop_search_impressions",
          "mobile_search_impressions",
          "direction_requests",
          "call_clicks",
          "website_clicks",
          "bookings",
          "food_orders",
          "food_menu_clicks",
        ].some((key) => metric[key] !== undefined)
      );
    },
    extractMetrics(metrics) {
      // console.log("Extracting metrics from:", metrics);
      return {
        desktop_maps_impressions:
          parseInt(metrics?.desktop_maps_impressions) || 0,
        mobile_maps_impressions:
          parseInt(metrics?.mobile_maps_impressions) || 0,
        desktop_search_impressions:
          parseInt(metrics?.desktop_search_impressions) || 0,
        mobile_search_impressions:
          parseInt(metrics?.mobile_search_impressions) || 0,
        direction_requests: parseInt(metrics?.direction_requests) || 0,
        call_clicks: parseInt(metrics?.call_clicks) || 0,
        website_clicks: parseInt(metrics?.website_clicks) || 0,
        bookings: parseInt(metrics?.bookings) || 0,
        food_orders: parseInt(metrics?.food_orders) || 0,
        food_menu_clicks: parseInt(metrics?.food_menu_clicks) || 0,
      };
    },
    async togglePreviousData(wid) {
      // console.log("togglePreviousData called for wid:", wid);
      this.$set(this.showPreviousData, wid, !this.showPreviousData[wid]);

      if (this.showPreviousData[wid] && !this.previousMetrics[wid]) {
        // console.log("Fetching previous metrics for wid:", wid);
        await this.fetchPreviousMetrics(wid);
      } else {
        console.log("Previous metrics already loaded or dropdown closed");
      }
    },

    async fetchPreviousMetrics(wid) {
      try {
        // console.log("Starting fetch Previous Metrics for wid:", wid);

        // Get the selected months from the current date range
        const selectedMonths = this.selectedDate?.months;
        // console.log("Selected months:", selectedMonths);
        // Check if we have valid cached data from today
        const cachedData = this.previousMonthMetricsInStore[wid]?.[selectedMonths];
        // console.log('cachedData:', cachedData)
        const today = new Date().toLocaleDateString('en-GB');
        if (cachedData && cachedData.lastFetchedDate === today) {
          this.$set(this.previousMetrics, wid, cachedData);
          return;
        }

        if (!selectedMonths && this.selectedDate.months == 12) {
          console.warn("No months selected");
          return;
        }

        // Calculate previous date range based on selected months
        const currentEndDate = new Date();
        const currentStartDate = new Date();
        currentStartDate.setMonth(currentStartDate.getMonth() - selectedMonths);

        const previousEndDate = new Date(currentStartDate);
        const previousStartDate = new Date(previousEndDate);
        previousStartDate.setMonth(
          previousStartDate.getMonth() - selectedMonths
        );

        const connectedAppsRef = collection(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          wid,
          "connected-apps"
        );

        const gbpDoc = await getDocs(
          query(
            connectedAppsRef,
            where("__name__", "==", "google_business_profile")
          )
        );

        if (!gbpDoc.empty) {
          const gbpData = gbpDoc.docs[0].data();
          const accounts = gbpData.accounts;

          if (accounts?.length) {
            const accountId = accounts[0].id;
            const location = accounts[0].locations?.[0];

            if (location) {
              const payload = {
                uid: this.currentUID,
                wid,
                platform: "google_business_profile",
                account: accountId,
                location: location.name,
                dateRange: {
                  start: previousStartDate.toISOString().split("T")[0],
                  end: previousEndDate.toISOString().split("T")[0],
                },
                resource_type: "detailed",
                type: "previous_months",
                months: selectedMonths,
              };

              const fetchPlatformMetrics = httpsCallable(
                functions,
                "fetchPlatformMetrics"
              );
              const result = await fetchPlatformMetrics(payload);
              // console.log("Raw API response:", result);
              if (result.data && result.data.length > 0) {
                const metricsData = result.data[0];

                const metrics = this.extractMetrics(metricsData);
                // console.log("Extracted metrics:", metrics);

                if (Object.values(metrics).some((value) => value > 0)) {
                  this.$set(this.previousMetrics, wid, metrics);
                  // Persist in store
                  this.$store.commit('SET_PREVIOUS_MONTH_METRICS', {
                    wid,
                    monthSelection: selectedMonths,
                    data: metrics
                  });
                } else {
                  const emptyMetrics = {
                    desktop_maps_impressions: 0,
                    mobile_maps_impressions: 0,
                    desktop_search_impressions: 0,
                    mobile_search_impressions: 0,
                    direction_requests: 0,
                    call_clicks: 0,
                    website_clicks: 0,
                    bookings: 0,
                    food_orders: 0,
                    food_menu_clicks: 0,
                  };
                  this.$set(this.previousMetrics, wid, emptyMetrics);
                  // Persist empty metrics
                  this.$store.commit('SET_PREVIOUS_MONTH_METRICS', {
                    wid,
                    monthSelection: selectedMonths,
                    data: emptyMetrics
                  });
                }
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching previous metrics:", error);
        this.$set(this.previousMetrics, wid, {
          desktop_maps_impressions: 0,
          mobile_maps_impressions: 0,
          desktop_search_impressions: 0,
          mobile_search_impressions: 0,
          direction_requests: 0,
          call_clicks: 0,
          website_clicks: 0,
          bookings: 0,
          food_orders: 0,
          food_menu_clicks: 0,
        });
      }
    },
    calculatePercentageChange(current, previous) {
      // If both values are 0, return 0%
      if (current === 0 && previous === 0) return 0;

      // If previous is 0 and current is not, return 100%
      if (previous === 0 && current > 0) return 100;

      // If current is 0 and previous is not, return -100%
      if (current === 0 && previous > 0) return -100;

      // Calculate percentage change
      const change = ((current - previous) / previous) * 100;

      // Cap the percentage between -100 and 100
      if (change > 100) return 100;
      if (change < -100) return -100;

      // Round to whole number
      return Math.round(change);
    },
    getPercentageIcon(current, previous) {
      // If both values are 0, show minus
      if (current === 0 && previous === 0) return "mdi-minus";

      // If there's any change, show appropriate arrow
      if (current > previous) return "mdi-arrow-up";
      if (current < previous) return "mdi-arrow-down";
      return "mdi-minus";
    },
    getPercentageClass(current, previous) {
      // If both values are 0, use gray
      if (current === 0 && previous === 0) return "text-gray-500";

      // If there's any change, use appropriate color
      if (current > previous) return "text-green-500";
      if (current < previous) return "text-red-500";
      return "text-gray-500";
    },
  },

  async mounted() {
    this.fetchGMBLogo();
    await this.fetchStores();

    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    this.$loader.hide();
    document.removeEventListener("click", this.handleClickOutside);
    this.destroyChart("metricsChart");
    this.destroyChart("mobileMetricsChart");
    this.destroyChart("desktopMetricsChart");
  },
};
</script>

<style scoped>
.text-xs {
  font-size: 0.75rem;
}

:deep(.v-text-field.location-input) {
  .v-input__slot {
    min-height: 40px !important;
  }

  .v-text-field__placeholder {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }

  input {
    font-size: 14px;
  }

  fieldset {
    border-color: rgba(0, 0, 0, 0.6) !important;
  }
}

.text-center {
  text-align: center;
}

img {
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.hover\:bg-gray-100:hover {
  background-color: #f7fafc;
}

.leading-tight {
  line-height: 1.25;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.skeleton-loader {
  display: inline-block;
  width: 50px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
}

.overflow-x-auto {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}

/* Optional: Custom scrollbar styling */
.overflow-x-auto::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tooltip-container {
  position: relative;
  z-index: 40;
}

.v-tooltip__content {
  z-index: 60 !important;
  position: fixed !important;
}

.v-icon {
  z-index: 30;
  position: relative;
}

.showStoreDropdown {
  background-color: white;
  /* Ensure solid background */
  z-index: 50;
  position: relative;
}

.date-select {
  position: relative;
  z-index: 40;
}

button.tabs {
  border-width: 2px;
  border-style: solid;
  border-color: #8056de;
}

button.tabs.active {
  background-color: #8056de;
  color: white;
}

.metrics-select {
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.no-left-margin :deep(.v-slide-group__wrapper) {
  margin-left: 0;
  padding-left: 0;
}

.no-left-margin :deep(.v-tabs-slider-wrapper) {
  margin-left: 0;
}

/* This ensures the v-select dropdown menu appears above everything */
:deep(.v-menu__content) {
  z-index: 60 !important;
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import { auth } from "../utils/firebase.utils.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    brandName: '',
    _isAdmin: false,
    _isAuth: false,
    _teamMemberAdded: false,
    _authUser: {},
    _muted: true,
    tourCompleted: false,
    _acceptInvite: false,
    _currentLocation: {
      name: "Vasai",
      latlong: "19.4003,72.7777",
    },
    _flyPlatforms: [],
    _flyPlatformsType: [],
    _flyPlatformsCategoryType: [],
    _currentUID: localStorage.getItem("CURRENT_UID") || "",
    _currentOriginalUID: "",
    // keeping track of the switched workspace in case page is refreshed and the store is reset
    _WID: localStorage.getItem("CURRENT_WORKSPACE_ID") || "default",
    _allWorkspaces: {},
    _currentUser: {},
    _currentWS: {},
    _referredBy: null,
    _campaign: null,
    _carCacheOlx: {},
    _carCacheBrandQuikr: {},
    _carCacheModelQuikr: {},
    _carCacheVariantQuikr: {},
    _carStateList: [],
    _carCityList: [],
    _carNeighbourhoodList: [],
    _currentWorkspaceOwner:
      localStorage.getItem("CURRENT_WORKSPACE_OWNER") || null,
    _selectedMetrics: [
      "direction_requests",
      "call_clicks",
      "website_clicks",
    ],
    _selectedMobileMetrics: [
      "mobile_maps_impressions",
      "mobile_search_impressions",
    ],
    _selectedDesktopMetrics: [
      "desktop_maps_impressions",
      "desktop_search_impressions",
    ],
    _allFetchedLocationStatsData: {},
    _locationStatsLastFetched: null,
    _selectedStatsMonth: 1,
    _selectedStatsLocations: [],
    _previousMonthMetricsInStore: {},
  },
  getters: {
    muted: (state) => state._muted,
    isAuth: (state) => state._isAuth,
    authUser: (state) => state._authUser,
    currentLocation: (state) => state._currentLocation,
    flyPlatforms: (state) => state._flyPlatforms,
    flyPlatformsType: (state) => state._flyPlatformsType,
    flyPlatformsCategoryType: (state) => state._flyPlatformsCategoryType,
    currentUID: () => {
      let workspaceId = localStorage.getItem("CURRENT_WORKSPACE_ID");
      let uid = (auth.currentUser || {}).uid;
      if (workspaceId) {
        return workspaceId.toString().split("__")[1] || uid;
      }
      return uid;
    },
    currentOriginalUID: (state) => state._currentOriginalUID,
    WID: () => {
      let storeWID = localStorage.getItem("CURRENT_WORKSPACE_ID") || "default";
      return storeWID.includes("__") ? storeWID.split("__")[0] : storeWID;
    },
    allWorkspaces: (state) => state._allWorkspaces,
    currentWorkspace: (state) => {
      let currentWorkspace = localStorage.getItem("CURRENT_WORKSPACE");
      currentWorkspace = JSON.parse(currentWorkspace);
      if(currentWorkspace) return currentWorkspace;
      return state._currentWS;
    },
    currentUser: (state) => {
      let currentWorkspace = localStorage.getItem("CURRENT_USER");
      currentWorkspace = JSON.parse(currentWorkspace);
      if(currentWorkspace) return currentWorkspace;
      return state._currentUser
    },
    referredBy: (state) => state._referredBy,
    isAdmin: (state) => state._isAdmin,
    campaign: (state) => state._campaign,
    carCacheOlx: (state) => state._carCacheOlx,
    carCacheBrandQuikr: (state) => state._carCacheBrandQuikr,
    carCacheModelQuikr: (state) => state._carCacheModelQuikr,
    carCacheVariantQuikr: (state) => state._carCacheVariantQuikr,
    carStates: (state) => state._carStateList,
    carCities: (state) => state._carCityList,
    carNeighbourhoods: (state) => state._carNeighbourhoodList,
    getCurrentWorkspaceOwner: (state) => state._currentWorkspaceOwner,
    selectedMetrics: (state) => state._selectedMetrics,
    selectedMobileMetrics: (state) => state._selectedMobileMetrics,
    selectedDesktopMetrics: (state) => state._selectedDesktopMetrics,
    allFetchedLocationStatsData: (state) => state._allFetchedLocationStatsData,
    locationStatsLastFetched: (state) => state._locationStatsLastFetched,
    selectedStatsMonth: (state) => state._selectedStatsMonth,
    selectedStatsLocations: (state) => state._selectedStatsLocations,
    previousMonthMetricsInStore: (state) => state._previousMonthMetricsInStore,
  },
  mutations: {
    setBrandName(state, brandName) {
      state.brandName = brandName;
    },
    SET_TOUR_COMPLETED(state, value) {
      state.tourCompleted = value;
    },
    SET_IS_ADMIN(state, value) {
      state._isAdmin = value;
    },
    SET_MUTED(state, value) {
      state._muted = value;
    },
    SET_AUTH(state, value) {
      state._isAuth = value;
    },
    SET_USER(state, value) {
      state._authUser = value;
    },
    SET_CURRENT_LOCATION(state, value) {
      localStorage.location = JSON.stringify(value);
      value.ts = new Date().getTime();
      state._currentLocation = value;
    },
    SET_FLY_PLATFORMS(state, value) {
      state._flyPlatforms = value;
    },
    CLEAR_FLY_PLATFORMS(state) {
      state._flyPlatforms = [];
    },
    SET_FLY_PLATFORMS_TYPE(state, value) {
      state._flyPlatformsType = value;
    },
    SET_FLY_PLATFORMS_CATEGORY_TYPE(state, value) {
      state._flyPlatformsCategoryType = value;
    },
    SET_CURRENT_WORKSPACE_ID(state, value) {
      state._WID = value;
      console.log("wid", value);
      /* Saving current workspace id to localstore to handle the case when the store is reset on page refresh and switched workspace is needed to be tracked */
      localStorage.setItem("CURRENT_WORKSPACE_ID", value);
    },
    SET_ALL_WORKSPACES(state, value) {
      state._allWorkspaces = value;
    },
    SET_CURRENT_UID(state, value) {
      state._currentUID = value;
    },
    SET_CURRENT__ORIGINAL_UID(state, value) {
      state._currentOriginalUID = value;
      localStorage.setItem("CURRENT_UID", value);
    },
    SET_CURRENT_USER(state, value) {
      state._currentUser = value;
    },
    SET_REFERRED_BY(state, value) {
      state._referredBy = value;
    },
    SET_CAMPAIGN(state, value) {
      state._campaign = value;
    },
    SET_CAR_CACHE_OLX(state, value) {
      state._carCacheOlx = value;
    },
    SET_CAR_BRAND_CACHE_QUIKR(state, value) {
      state._carCacheBrandQuikr = value;
    },
    SET_CAR_MODEL_CACHE_QUIKR(state, value) {
      state._carCacheModelQuikr = value;
    },
    SET_CAR_VARIANT_CACHE_QUIKR(state, value) {
      state._carCacheVariantQuikr = value;
    },
    SET_CAR_LOCATION_STATES(state, value) {
      state._carStateList = value;
    },
    SET_CAR_LOCATION_CITIES(state, value) {
      state._carCityList = value;
    },
    SET_CAR_LOCATION_NEIGHBOURHOODS(state, value) {
      state._carNeighbourhoodList = value;
    },
    SET_CURRENT_WORKSPACE_OWNER(state, workspaceOwner) {
      state._currentWorkspaceOwner = workspaceOwner;
    },
    SET_CURRENT_WORKSPACE(state, workspace) {
      state._currentWS = workspace;
    },
    SET_TEAM_MEMBER_ADDED(state, value) {
      state._teamMemberAdded = value;
    },
    SET_ACCEPT_INVITE(state, value) {
      state._acceptInvite = value;
    },
    SET_SELECTED_METRICS(state, value) {
      state._selectedMetrics = value;
    },
    SET_SELECTED_MOBILE_METRICS(state, value) {
      state._selectedMobileMetrics = value;
    },
    SET_SELECTED_DESKTOP_METRICS(state, value) {
      state._selectedDesktopMetrics = value;
    },
    SET_SELECTED_STATS_MONTH(state, value) {
      console.log('SET_SELECTED_STATS_MONTH :', value)
      state._selectedStatsMonth = value;
    },
    SET_ALL_FETCHED_LOCATION_STATS_DATA(state, { wid, monthSelection, date, data }) {
      // console.log('wid :', wid, 'date :', date, 'data :', data)
      if(!state._allFetchedLocationStatsData) {
        state._allFetchedLocationStatsData = {};
      }
      if(!state._allFetchedLocationStatsData[wid]) {
        state._allFetchedLocationStatsData[wid] = {};
      }
      if(!state._allFetchedLocationStatsData[wid][monthSelection]) {
        state._allFetchedLocationStatsData[wid][monthSelection] = {};
      }
      state._allFetchedLocationStatsData[wid][monthSelection][date] = data;
      const today = new Date();
      if (!state._locationStatsLastFetched) {
        state._locationStatsLastFetched = {};
      }
      if (!state._locationStatsLastFetched[wid]) {
        state._locationStatsLastFetched[wid] = {};
      }
      if (!state._locationStatsLastFetched[wid][monthSelection]) {
        state._locationStatsLastFetched[wid][monthSelection] = {};
      }
      state._locationStatsLastFetched[wid][monthSelection] = today.toLocaleDateString('en-GB'); // DD-MM-YYYY format
      // console.log('after after :', state._allFetchedLocationStatsData)
    },
    SET_SELECTED_STATS_LOCATIONS(state, value) {
      state._selectedStatsLocations = value;
    },
    SET_PREVIOUS_MONTH_METRICS(state, { wid, monthSelection, data }) {
      if (!state._previousMonthMetricsInStore) {
        state._previousMonthMetricsInStore = {};
      }
      if (!state._previousMonthMetricsInStore[wid]) {
        state._previousMonthMetricsInStore[wid] = {};
      }
      state._previousMonthMetricsInStore[wid][monthSelection] = {
        ...data,
        lastFetchedDate: new Date().toLocaleDateString('en-GB') // Store fetch date
      };
    },
  },
  actions: {
    setTourCompleted({ commit }, value) {
      commit("SET_TOUR_COMPLETED", value);
    },
    setCurrentLocation(context) {
      let local = localStorage.location;
      let location = null;
      if (local) {
        location = JSON.parse(local);
      }
      if (location) {
        location.ts = new Date().getTime();
        context.commit("SET_CURRENT_LOCATION", location);
      }
    },

    setCurrentWorkspaceOwner({ commit }, workspaceOwner) {
      commit("SET_CURRENT_WORKSPACE_OWNER", workspaceOwner);
    },

    async fetchAuthUser({ commit }) {
      const user = auth.currentUser;
      if (user) {
        commit("SET_USER", user);
        commit("SET_AUTH", true);
      } else {
        commit("SET_USER", {});
        commit("SET_AUTH", false);
      }
    },
    addTeamMember({ commit }, value) {
      commit("SET_TEAM_MEMBER_ADDED", value);
    },
    acceptTeamInvite({ commit }, value) {
      commit("SET_ACCEPT_INVITE", value);
    },
  },
  modules: {},
});
